<!-- <template>
  <DashboardLayout>
    <div>
      <div class="flex justify-between items-center mb-4">
        <h2 class="admin-table-title">Doctor Consultation</h2>
      </div>

      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(message, i) in filteredDoctorConsultation" :key="i">
          <td>{{ `${message?.doctor.firstName}${message?.doctor.lastName}` }}</td>
          <td>{{ message?.doctor?.speciality }}</td>
          <td>{{ message?.doctor?.accountNumber }}</td>
          <td>{{ formatDate(message?.doctor?.createdDate) }}</td>
          <td>{{ message?.consultantCount }}</td>
          <td>
            <v-btn color="primary" @click="handleConsultant(message.consultants)">
              Consultation Detail</v-btn
            >
          </td>
      
        </tr>
      </AdminTable>

      <div v-if="filteredDoctorConsultation.length > 0" class="my-4">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template> -->

<template>
  <DashboardLayout>
    <div>
      <div class="flex justify-between items-center mb-4">
        <h2 class="admin-table-title">Doctor Consultation</h2>
      </div>

      <AdminTable :columns="COLUMNS" :loading="loading">
        <template v-if="filteredDoctorConsultation.length === 0">
          
            <tr>
            <td :colspan="COLUMNS.length" class="no-data-text my-2">
              <v-icon large class="no-data-icon">mdi-alert-circle-outline</v-icon>
              <div>
                 No Record Found for Doctor Consultation
              </div>
            
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="table-row" v-for="(message, i) in filteredDoctorConsultation" :key="i">
            <td>{{ `${message?.doctor.firstName} ${message?.doctor.lastName}` }}</td>
            <td>{{ message?.doctor?.speciality }}</td>
            <td>{{ message?.doctor?.accountNumber }}</td>
            <td>{{ formatDate(message?.doctor?.createdDate) }}</td>
            <td>{{ message?.consultantCount }}</td>
            <td>
              <v-btn color="primary" @click="handleConsultant(message.consultants)">
                Consultation Detail
              </v-btn>
            </td>
          </tr>
        </template>
      </AdminTable>

      <div v-if="filteredDoctorConsultation.length > 0" class="my-4">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";

export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
  },
  data: () => ({
    COLUMNS: [
      "Doctor Name",
      "Speciality",
      "Account Number",
      "Profile Creation",
      "Total Consultation",
      "Action",
    ],
    menuloading: false,
    showdetails: false,

    selectedStatus: "All", // Default to "All"
  }),

  computed: {
    ...mapState("doctorConsultation", {
      loading: (state) => state.loading,
      doctorConsultations: (state) => state.doctorConsultations,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
    filteredDoctorConsultation() {
      return this.doctorConsultations;
    },
  },
  created() {
    this.fetchDoctorConsultation();
  },
  methods: {
    async fetchDoctorConsultation() {
      await this.$store.dispatch("doctorConsultation/fetchDoctorConsultation");
    },
    setPagination(page) {
      this.$store.dispatch("doctorConsultation/fetchDoctorConsultation", { page });
    },

    handleConsultant(value) {
      sessionStorage.setItem("selectedConsultation", JSON.stringify(value));
      this.$store.dispatch("doctorConsultation/selectConsultation",value);
      this.$router.push('/consultation-detail');
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = String(date.getUTCDate()).padStart(2, "0");
      const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getUTCFullYear();
      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style scoped>
.admin-table-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  white-space: nowrap;
}

.table-row td {
  padding: 12px;
  white-space: nowrap;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.status-paid {
  color: green;
  padding: 3px;
  border-radius: 8px;

  font-weight: bold;
}
.status-unpaid {
  color: red;
  padding: 3px;
  border-radius: 8px;

  font-weight: bold;
}
.no-article-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Increase the font size */
  height: 100px; /* Optional: adjust height to ensure vertical centering */
  font-weight: bold; /* Optional: make the text bold */
  color: #555; /* Optional: change the text color */
}

.filter-container {
  margin-bottom: 16px;
  max-width: 200px;
}
.no-data-text {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 20px 0;
}
</style>
