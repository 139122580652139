import { handleError, handleSuccess } from "@/utils/handleResponse";
import ambulanceService from "@/services/Ambulance/ambulanceService";

export default {
  namespaced: true,
  state: {
    loading: false,
    page: 1,
    limit: 10,
    sort: "ASC",
    requestedUser: [],
    totalpages: 1,
    followLoading:false,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FOLOW_LOADING(state, value) {
        state.followLoading = value;
      },
    SET_REQUESTS_USERS(state, value) {
      state.requestedUser = value;
    },
    SET_PAGE(state, value) {
      state.page = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalpages = value;
    },
  },
  actions: {
    fetchRequestsUser: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await ambulanceService.fetchRequestsUser(
          `/getRegisterUser?page=1`
        );
        commit("SET_LOADING", false);
        console.log("response.data=>", response.data);
        commit("SET_TOTAL_PAGES", response.data.totalPages);
        commit("SET_REQUESTS_USERS", response.data.user);
      } catch (error) {
        commit("SET_LOADING", false);
        commit("SET_REQUESTS_USERS", []);
        commit("SET_TOTAL_PAGES", 1);
        handleError(error.message);
      }
    },
    handlePagination: async ({ commit },{page}) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_PAGE",page);
        const response = await ambulanceService.fetchRequestsUser(
          `/getRegisterUser?page=${page}`
        );
        commit("SET_REQUESTS_USERS", response.data.user);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_REQUESTS_USERS", []);
        commit("SET_LOADING", false);
      }
    },
    // New action to create an article
    createFollowUp: async ({ commit }, value) => {
      try {
        commit("SET_FOLOW_LOADING", true);
       
       
        const response = await ambulanceService.createFollowUp("saveFollowUpForm",value);
        console.log("response",response)
        if(response.status==200){
          handleSuccess("Add Follow Up Message Successfully");
        }else{
          handleError(response.data.error.message);
        }
        commit("SET_FOLOW_LOADING", false);
        // Call onSuccess callback if provided   
     
       
      } catch (error) {
        commit("SET_FOLOW_LOADING", false);
        handleError(error.message);

        // Call onError callback if provided
       
      }
    },
  
    // deleteArticle: async ({ commit }, id) => {
    //   try {
      
     
    //     const response = await articleService.deleteArticle(`deleteBlog/${id}`);
    //     console.log("response",response)
    //     if(response.status==200){
    //       handleSuccess("Delete Article Successfully");
    //     }else{
    //       handleError(response.data.error.message);
    //     }
    //     commit("SET_LOADING", false);
    //     // Call onSuccess callback if provided   
     
       
    //   } catch (error) {
    //     commit("SET_LOADING", false);
    //     handleError(error.message);

    //     // Call onError callback if provided
       
    //   }
    // },
    // async getSingleArticle({ commit }, articleId) {
    //   try{
    //     const response = await articleService.getSingleArticle(`getBlogsById/${articleId}`);
    //     if(response.status==200){
    //      return response?.data
    //     }else{
    //       handleError(response.data.error.message);
    //       commit("SET_LOADING", false);
    //     }
    //   }catch(error){
    
    //     handleError("Something went wrong");
    //     commit("SET_LOADING", false);
    //   }
     
      
    // },
  },
};
