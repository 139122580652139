<template>
  <DashboardLayout>
    <div>
      <div class="flex justify-between items-center mb-4">
        <h2 class="admin-table-title">Ambulance Users Request</h2>
      </div>

      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(request, i) in requestedUser" :key="i">
          <td>{{ formatDate(request?.created_at) }}</td>
          <td>{{ `${request?.patient?.firstName} ${request?.patient?.lastName}` }}</td>
          <td>{{ request?.patient?.email }}</td>
          <td>{{ request?.patient?.phoneNumber }}</td>
          <td>{{ request?.patient?.address }}</td>

          <!-- Status Column with Conditional Background Color Using v-chip -->
          <td>
            <v-chip :color="getStatusColor(request?.status)" dark class="status-chip">
              {{ request?.status }}
            </v-chip>
          </td>

          <!-- Action Button for Follow Up -->
          <td v-if="request?.status == 'not fulfilled'">
            <v-btn color="primary" @click="followMessage(request.id)"> Follow Up </v-btn>
          </td>
          <td v-if="request?.status == 'fulfilled'">
            <!-- <v-btn color="primary" @click="followMessage(request.id)">View </v-btn> -->
             <v-btn icon @click="followMessageDetail(request)" color="primary">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </td>
        </tr>
      </AdminTable>

      <div v-if="requestedUser.length > 0" class="my-4">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>

      <overlay-loader :loading="menuloading" :description="'Loading...'" />
      <v-dialog v-model="addFollow" max-width="600px" :key="modalkey">
        <AddFollowUpMessage
          @closeModal="addFollow = !addFollow"
          :loading="followLoading"
          @postFollowUp="postFollowUp"
        />
      </v-dialog>
      <v-dialog v-model="followDetail" max-width="600px" :key="modalkey">
        <FollowUpMessageDetail
          @closeModal="followDetail = !followDetail"
          :detailFollow="detailFollow"
        
        />
      </v-dialog>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import AddFollowUpMessage from "@/elements/Modals/AddFollowUpMessage.vue";
import FollowUpMessageDetail from "@/elements/Modals/FollowUpMessageDetail.vue";
export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
    AddFollowUpMessage,
    FollowUpMessageDetail
  },
  data: () => ({
    COLUMNS: ["Date", "Name", "Email", "Phone Number", "Address", "Status", "Action"],
    menuloading: false,
    addFollow: false,
    followDetail: false,
    detailFollow:{},
    id: "",
    article: {}, // Initialize article as an empty object
    modalkey: 0,
  }),
  computed: {
    ...mapState("ambulance", {
      loading: (state) => state.loading,
      followLoading: (state) => state.followLoading,
      requestedUser: (state) => state.requestedUser,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
  },
  created() {
    this.fetchRequestUser();
  },
  methods: {
    async fetchRequestUser() {
      await this.$store.dispatch("ambulance/fetchRequestsUser");
    },
    setPagination(page) {
      this.$store.dispatch("ambulance/handlePagination", { page });
    },
    async postFollowUp(title, description, status) {
      let data = {
        id: this.id,
        title,
        description,
        status,
      };
      await this.$store.dispatch("ambulance/createFollowUp", data);
      await this.$store.dispatch("ambulance/fetchRequestsUser");
      this.id = "";
      this.addFollow = false;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "Invalid Date";
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    followMessage(id) {
      this.id = id;
      this.modalkey++;
      this.addFollow = !this.addFollow;
    },
     followMessageDetail(content) {
      this.detailFollow = content;
      this.modalkey++;
      this.followDetail = !this.followDetail;
    },
    getStatusColor(status) {
      if (status === "fulfilled") return "green";
      if (status === "not fulfilled") return "red";
      return "grey";
    },
  },
};
</script>

<style scoped>
.admin-table-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  white-space: nowrap;
}

.table-row td {
  padding: 12px;
  white-space: nowrap;
}

.status-chip {
  font-weight: medium;
  border-radius: 10px; /* Rounded corners */
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.no-article-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  height: 100px;
  font-weight: bold;
  color: #555;
}

.filter-container {
  margin-bottom: 16px;
  max-width: 200px;
}
</style>
