import {  adminApi } from "@/api/api";


export default {

  async fetchRequestsUser(url) {
    return adminApi.get(url);
  },
  async createFollowUp(url,data) {
    return adminApi.post(url,data);
  },
};
