<!-- <template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div>
      <div class="my">
        <h2 class="text-center font-weight-bold">Refund Payment </h2>
      </div>
      <v-row>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Department:</div>
          <div class="text-lg">
            {{ labs?.testInformaion?.depName }}
          </div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Name:</div>
          <div class="text-lg">
            {{ labs?.testInformaion?.testName }}
          </div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Price:</div>
          <div class="text-lg">
            {{ labs?.testInformaion?.priceWithTax }}
          </div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Type:</div>
          <div class="text-lg">
            {{ labs?.testType }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Transaction Id:</div>
          <div class="text-lg">
            {{ labs?.transactionRecord?.transactionId }}
          </div>
        </v-col>
      
     
       
    
    
     

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Date:</div>
          <div class="text-lg">
            {{ formatDate(labs?.createdAt) }}
          </div>
        </v-col>
       
      </v-row>
       <div class="my-6 w-full">
          <PrimaryButton
            :loading="loading"
            :color="BRANDCOLOR"
            :large="true"
            :block="true"
            @click="updateRefund"
          >
           Refund Payment
          </PrimaryButton>
        </div>
    </div>
  </v-card>
</template>
<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
export default {
  components: {
    PrimaryButton
  },
  props: {
    labs: {
      type: Object,
      default: () => ({}),
    },
     loading: {
      type: Boolean,
    },
  },

  data() {
    return {
        BRANDCOLOR
    };
  },
  methods:{
     updateRefund(){
      this.$emit("updateRefund");
    },
      formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "Invalid Date";
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
  }
};
</script>
<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}
.my {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}

.showData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style> -->


<template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div>
      <div class="my">
        <h2 class="text-center font-weight-bold">Refund Payment</h2>
      </div>
      <!-- Iterate over the tests and display information -->
      <v-row v-for="(test, index) in labs?.testInformaion" :key="index">
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Department:</div>
          <div class="text-lg">{{ test?.depName }}</div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Name:</div>
          <div class="text-lg">{{ test?.testName }}</div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Price:</div>
          <div class="text-lg">{{ test?.priceWithTax }}</div>
        </v-col>
       
      </v-row>

      <!-- Transaction details -->
      <v-row>
       <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Type:</div>
          <div class="text-lg">{{ labs?.testType }}</div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
        
          <div class="font-semibold grey--text">Transaction Id:</div>
          <div class="text-lg">{{ labs?.transactionRecord?.id }}</div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Date:</div>
          <div class="text-lg">{{ formatDate(labs?.createdAt) }}</div>
        </v-col>
      </v-row>
      <div class="my-6 w-full">
        <PrimaryButton
          :loading="loading"
          :color="BRANDCOLOR"
          :large="true"
          :block="true"
          @click="updateRefund"
        >
          Refund Payment
        </PrimaryButton>
      </div>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },
  props: {
    labs: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      BRANDCOLOR,
    };
  },
  methods: {
    updateRefund() {
      this.$emit("updateRefund");
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "Invalid Date";
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}
.my {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}

.showData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>

