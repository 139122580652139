<!-- <template>
  <DashboardLayout>
    <div>
      <h2>Continuous Tracking</h2>
      <div class="my-2">
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          outlined
          dense
          style="border-radius: 10px"
          label="Search"
          single-line
        />
      </div>

      <TrackerHeader :title="'Top Doctors'" />
      <div class="admin-track-table">
        <TrackerTable :columns="trackercolumn">
          <tr class="table-row" v-for="(row, i) in trackertabledata" :key="i">
            <td>
              <v-avatar class="mr-2"
                ><img src="@/assets/subadmin.svg" /> </v-avatar
              >{{ row.name }}
            </td>
            <td>{{ row.speciality }}</td>
            <td>{{ row.numOfConsultations }}</td>
          </tr>
        </TrackerTable>
      </div>

      <div class="my-6">
      <TrackerHeader :title="'Least Favourite Doctor'" />
      <div class="admin-track-table">
        <TrackerTable :columns="trackercolumn">
          <tr class="table-row" v-for="(row, i) in trackertabledata" :key="i">
            <td>
              <v-avatar class="mr-2"
                ><img src="@/assets/subadmin.svg" /> </v-avatar
              >{{ row.name }}
            </td>
            <td>{{ row.speciality }}</td>
            <td>{{ row.numOfConsultations }}</td>
          </tr>
        </TrackerTable>
      </div>
    </div>
    </div>
  </DashboardLayout>
</template>


<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import TrackerTable from "@/elements/Tables/TrackerTable.vue";
import TrackerHeader from "@/components/Tracker/TrackerHeader.vue";
export default {
  components: {
    DashboardLayout,
    TrackerTable,
    TrackerHeader,
  },

  data() {
    return {
      trackercolumn: ["Name", "Speciality", "Number of Consultation"],
      trackertabledata: [
        {
          name: "Dr Shannon",
          image: "",
          speciality: "Dentist",
          numOfConsultations: 10,
        },
        {
          name: "Dr Shannon",
          image: "",
          speciality: "Surgeon",
          numOfConsultations: 30,
        },
        {
          name: "Dr Shannon",
          image: "",
          speciality: "Dentist",
          numOfConsultations: 40,
        },
      ],
    };
  },
};
</script> -->

<template>
  <DashboardLayout>
    <div>
      <div class="mainHeader mb-4">
        <h2 class="admin-table-title">Health Tracker Users Request</h2>
        <div class="admin-filter">
          <v-select
            v-model="status"
            :items="statusValues"
            label="Tracking Status"
            dense
            outlined
            hide-details="auto"
            class="custom-input"
          />
        </div>
      </div>

      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(labs, i) in trackerLabTest" :key="i">
          <td>{{ `${labs?.firstName} ${labs?.lastName}` }}</td>
          <td>{{ labs?.email }}</td>
          <td>{{ labs?.phoneNumber }}</td>

          <!-- Payment Status with styles -->
          <td :class="getPaymentStatusClass(labs.paymentStatus)">
            <v-icon v-if="labs.paymentStatus === 'Paid'" color="green"
              >mdi-check-circle</v-icon
            >
            <v-icon v-if="labs.paymentStatus === 'Unpaid'" color="red"
              >mdi-alert-circle</v-icon
            >
            {{ labs?.paymentStatus }}
          </td>

          <!-- Tracking Status with styles -->
          <td :class="getTrackingStatusClass(labs.trackingStatus)">
            <v-icon v-if="labs.trackingStatus === 'In Progress'" color="blue"
              >mdi-progress-clock</v-icon
            >
            <v-icon v-if="labs.trackingStatus === 'Pending'" color="orange"
              >mdi-timer-sand</v-icon
            >
            <v-icon v-if="labs.trackingStatus === 'Cancelled'" color="red"
              >mdi-close-circle</v-icon
            >
            <v-icon v-if="labs.trackingStatus === 'Fullfilled'" color="green"
              >mdi-check-circle</v-icon
            >
            {{ labs?.trackingStatus }}
          </td>
          <td v-if="labs?.transactionRecord?.refundStatus == 'In Progress'">
            <v-btn color="primary" @click="showRefundDetail(labs)">Refund</v-btn>
          </td>
          <!-- <td v-if="labs?.transactionRecord?.refundStatus == 'Completed'">Completed</td>

          <td> -->
          <td v-if="labs?.transactionRecord?.refundStatus == 'Completed'">Completed</td>
          <td v-else-if="labs?.transactionRecord?.refundStatus != 'In Progress'">N/A</td>

          <td>
            <v-btn color="primary" @click="showLabDetail(labs)">Detail</v-btn>
          </td>

          <td v-if="labs?.trackingStatus == 'In Progress'">
            <v-btn color="primary" @click="followMessage(labs)">Follow Up </v-btn>
          </td>
          <td v-if="labs?.trackingStatus == 'Pending'">
            <v-btn color="primary" @click="followMessage(labs)">Follow Up </v-btn>
          </td>
          <td v-if="labs?.trackingStatus != 'In Progress'"></td>
        </tr>
      </AdminTable>

      <div v-if="trackerLabTest.length > 0" class="my-4">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>

      <overlay-loader :loading="menuloading" :description="'Loading...'" />
      <v-dialog v-model="addFollow" max-width="600px" :key="modalkey">
        <UpdateHealthTrackerStatus
          @closeModal="addFollow = !addFollow"
          :paymentStatus="paymentStatus"
          :loading="followLoading"
          @postFollowUp="postFollowUp"
        />
      </v-dialog>
      <v-dialog v-model="showDetail" max-width="600px" :key="modalkey">
        <HealthTrackerDetail @closeModal="showDetail = !showDetail" :labs="labsData" />
      </v-dialog>
      <v-dialog v-model="showRefund" max-width="600px" :key="modalkey">
        <RefundPayment
          @closeModal="showRefund = !showRefund"
          :labs="labsData"
          @updateRefund="updateRefund"
          :loading="followLoading"
        />
      </v-dialog>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import UpdateHealthTrackerStatus from "@/elements/Modals/UpdateHealthTrackerStatus.vue";
import HealthTrackerDetail from "@/elements/Modals/HealthTrackerDetail.vue";
import RefundPayment from "@/elements/Modals/RefundPayment.vue";
export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
    UpdateHealthTrackerStatus,
    HealthTrackerDetail,
    RefundPayment,
  },
  data: () => ({
    COLUMNS: [
      "Name",
      "Email",
      "Phone Number",
      "Payment Status",
      "Tracking Status",
      "Refund Payment",
      "Detail",
      "Action",
    ],
    status: "All",
    statusValues: ["All", "Pending", "In Progress", "Cancelled", "Fullfilled"],
    menuloading: false,
    addFollow: false,
    showDetail: false,
    showRefund: false,
    labsData: {},
    id: "",
    modalkey: 0,
    paymentStatus: "",
  }),
  computed: {
    ...mapState("trackerLabTests", {
      loading: (state) => state.loading,
      followLoading: (state) => state.followLoading,
      trackerLabTest: (state) => state.trackerLabTest,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
  },
  created() {
    this.fetchHealthTracker(this.status);
  },
  watch: {
    status(newStatus) {
      this.fetchHealthTracker(newStatus);
    },
  },
  methods: {
    getPaymentStatusClass(status) {
      if (status === "paid") return "status-paid";
      if (status === "unpaid") return "status-unpaid";
      return "";
    },
    getTrackingStatusClass(status) {
      if (status === "In Progress") return "status-in-progress";
      if (status === "Pending") return "status-pending";
      if (status === "Cancelled") return "status-cancelled";
      if (status === "Fullfilled") return "status-fullfilled";
      return "";
    },
    async fetchHealthTracker(status) {
      await this.$store.dispatch("trackerLabTests/fetchHealthTracker", { status });
    },
    setPagination(page) {
      this.$store.dispatch("trackerLabTests/handlePagination", {
        page,
        status: this.status,
      });
    },
    async postFollowUp(status, service) {
      let data = {
        trackerId: this.id,
        trackingStatus: status,
        service,
      };
      await this.$store.dispatch("trackerLabTests/createFollowUp", data);
      await this.fetchHealthTracker(this.status);
      this.id = "";
      this.addFollow = false;
    },
    async updateRefund() {
      let data = {
        trackerId: this.labsData.id,
        id: this?.labsData.transactionRecord?.id,
        sendEmail: this.labsData.email,
        refundStatus: "Completed",
      };
      await this.$store.dispatch("trackerLabTests/updateRefundStatus", data);
      await this.fetchHealthTracker(this.status);
      this.labsData = {};
      this.showRefund = false;
    },

    followMessage(data) {
      this.id = data.id;
      this.paymentStatus = data.trackingStatus;
      this.modalkey++;
      this.addFollow = !this.addFollow;
    },
    showLabDetail(data) {
      this.labsData = data;
      this.modalkey++;

      this.showDetail = !this.showDetail;
    },
    showRefundDetail(data) {
      this.labsData = data;
      this.modalkey++;

      this.showRefund = !this.showRefund;
    },
  },
};
</script>

<style scoped>
/* Payment Status Styles */
.status-paid {
  color: green;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}
.status-unpaid {
  color: red;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}
.status-pending {
  color: orange;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}

/* Tracking Status Styles */
.status-in-progress {
  color: blue;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}
.status-cancelled {
  color: red;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}
.status-fullfilled {
  color: green;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  font-weight: bold;
}
.no-data-text {
  text-align: center;
  font-size: 16px;
  color: #666;
  padding: 20px 0;
}
</style>
