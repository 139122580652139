<!-- <template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="closeModal" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <h2 class="py-3 text-center my-4">Add New Event</h2>

    <v-form ref="form" v-model="formValid">
      <div class="my-4">
        <v-text-field
          v-model="eventName"
          outlined
          dense
          :rules="[v => !!v || 'Event Name is required']"
          required
        />
      </div>

     
         <div class="my-4">
        <div class="my-2">Event Message</div>
        <div>
          <v-textarea
            dense
            outlined
            v-model="eventMessage"
            :rules="[(v) => !!v || 'Event Message is required']"
            required
          />
        </div>

         <div class="my-4">
        <div class="my-2">Select Date</div>
        <v-text-field
          outlined
          v-model="date"
          type="date"
          dense
          :rules="[(v) => !!v || 'Date is required']"
          required
        />
      </div>
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        @click="validateAndSubmit"
      >
        Create 
      </PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },

  props: {
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      BRANDCOLOR,
      eventName: "",
      eventMessage:"",
   
      date: "",
      smsError: false,
      formValid: false,
    
    };
  },

  methods: {
    validateAndSubmit() {
      // Validate the Quill Editor content
      this.smsError = this.eventMessage.trim() === "";

      // Check if the form is valid
      if (this.$refs.form.validate() && !this.smsError) {
        // Submit the form if validation passes
        this.postDailyText();
      }
    },
    postDailyText() {
        this.$emit("postDailyText", this.sms, this.date, this.selectedHour, "YD");
    },

    closeModal() {
      this.$refs.form.reset();
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style> -->

<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <!-- <v-btn @click="closeModal" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
    </div>

    <h2 class="py-3 text-center my-4">
      {{ isEditMode ? "Update Event" : "Add New Event" }}
    </h2>

    <v-form ref="form" v-model="formValid">
      <div class="my-4">
        <v-text-field
          v-model="eventName"
          outlined
          dense
          :rules="[(v) => !!v || 'Event Name is required']"
          required
          label="Event Name"
        />
      </div>

      <div class="my-4">
        <div class="my-2">Event Message</div>
        <v-textarea
          dense
          outlined
          v-model="eventMessage"
          :rules="[(v) => !!v || 'Event Message is required']"
          required
        />
      </div>

      <div class="my-4">
        <div class="my-2">Select Date</div>
        <v-text-field
          outlined
          v-model="date"
          type="date"
          dense
          :rules="[(v) => !!v || 'Date is required']"
          required
          label="Event Date"
        />
      </div>
    </v-form>

    <div class="my-6">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        :disable="loading"
        @click="validateAndSubmit"
      >
        {{ isEditMode ? "Update" : "Create" }}
      </PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },

  props: {
    loading: {
      type: Boolean,
    },
    event: {
      type: Object,
      default: () => null,
    },
  },

  data() {
    return {
      BRANDCOLOR,
      eventName: "",
      eventMessage: "",
      date: "",
      formValid: false,
      isEditMode: false, // Flag to determine if editing or creating
    };
  },

  watch: {
    event: {
      immediate: true,
      handler(newEvent) {
        if (newEvent) {
          this.isEditMode = true;
          this.eventName = newEvent.eventTitle || "";
          this.eventMessage = newEvent.eventMessage || "";
          this.date = newEvent.eventDate || "";
        } else {
          this.isEditMode = false;
        }
      },
    },
  },

  methods: {
    validateAndSubmit() {
      console.log("agia===>");
      this.smsError = this.eventMessage.trim() === "";

      if (this.$refs.form.validate() && !this.smsError) {
        if (this.isEditMode) {
          // Update the event if in edit mode
          this.updateEvent();
        } else {
          // Create a new event if not in edit mode
          this.postDailyText();
        }
      }
    },

    postDailyText() {
      this.$emit("postEvent", {
        eventTitle: this.eventName,
        eventMessage: this.eventMessage,
        eventDate: this.date,
        status: false,
      });
    },

    updateEvent() {
      console.log("this.eventName", this.eventName);
      this.$emit("postEvent", {
        id: this.event.id, // Pass the event ID for updating
        eventTitle: this.eventName,
        eventMessage: this.eventMessage,
        eventDate: this.date,
        status: false,
      });
    },

    closeModal() {
      this.$refs.form.reset();
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
