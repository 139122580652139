import {  adminApi } from "@/api/api";


export default {

  async fetchHealthTrackertest(url) {
    return adminApi.get(url);
  },
  async updateFollowUp(url,data) {
    return adminApi.post(url,data);
  },
  async updateRefundStatus(url,data) {
    return adminApi.post(url,data);
  },
};
