<template>
  <v-card class="pt-2 px-6 pb-2">
    <div class="d-flex justify-end">
      <v-btn @click="closeModal" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <h2 class="py-2 text-center my-2">Send Bulk SMS</h2>

    <v-form ref="form" v-model="formValid">
      <!-- Campaign Name and Date on the Same Row -->
      <v-row>
        <v-col cols="12" md="6">
          <div class="my-2">Campaign Name</div>
          <v-text-field v-model="campaignName" outlined dense :rules="[(v) => !!v || 'Campaign name is required']"
            required />
        </v-col>
        <v-col cols="12" md="6">
          <div class="my-2">Select Date</div>
          <v-text-field v-model="date" type="date" outlined dense
            :rules="[(v) => immediate_schedule || !!v || 'Date is required']" />
        </v-col>
      </v-row>

      <!-- Hour and Country on the Same Row -->
      <v-row>
        <v-col cols="12" md="6">
          <div class="my-2">Select Hour</div>
          <v-select v-model="selectedHour" :items="hours" outlined dense label="Hour"
            :rules="[(v) => immediate_schedule || !!v || 'Hour is required']" :disabled="immediate_schedule" />
        </v-col>
        <v-col cols="12" md="6">
          <div class="my-2">Select Country</div>
          <v-select v-model="selectedCountry" :items="['Nigeria', 'Kenya']" outlined dense label="Country"
            :rules="[(v) => !!v || 'Country is required']" required />
        </v-col>
      </v-row>

      <!-- SMS Content -->
      <div class="my-2">
        <div class="my-2">Enter Content</div>
        <v-textarea dense outlined v-model="sms" :rules="[(v) => !!v || 'Content is required']" required />
      </div>

      <div class='flex flex-col'>
        <label for="referrer">Please select the subscribers to send the message to:</label>
        <v-select 
          class="mt-4 w-1/2"
          v-model="all_subscribers" 
          :items="subscriberOptions" 
          outlined 
          dense 
          label="Subscribers"
          :rules="[(v) => !!v || 'Required field']" 
          required 
          item-value="value" 
          item-text="text" 
        />
      </div>

      <!-- Send Right Now Checkbox -->
      <v-checkbox v-model="immediate_schedule" label="Send Right Now" />

    </v-form>

    <!-- Submit Button -->
    <div class="customButton">
      <PrimaryButton :loading="loading" :color="BRANDCOLOR" :large="true" :block="true" @click="validateAndSubmit">
        Send
      </PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import { getDate, getTime } from "@/utils/formatter";

export default {
  components: {
    PrimaryButton,
  },

  props: {
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      BRANDCOLOR,
      sms: "",
      selectedHour: "",
      selectedCountry: "",
      date: "",
      campaignName: "",
      smsError: false,
      formValid: false,
      hours: this.generateHours(),
      immediate_schedule: false, // Checkbox value for "Send Right Now"
      all_subscribers: null,
      subscriberOptions: [
        { text: 'All Subscribers', value: 'all' },
        { text: 'Active Subscribers', value: 'active' }
      ]
    };
  },

  methods: {
    generateHours() {
      const hours = [];
      for (let hour = 0; hour < 24; hour++) {
        let period = hour < 12 ? "AM" : "PM";
        let displayHour = hour % 12 === 0 ? 12 : hour % 12;
        for (let minutes of ["00", "15", "30", "45"]) {
          hours.push(`${displayHour}:${minutes} ${period}`);
        }
      }
      return hours;
    },

    validateAndSubmit() {

      // Validate SMS content
      this.smsError = this.sms.trim() === "";

      // Ensure validation works with the optional hour and date when "Send Right Now" is checked
      if (this.$refs.form.validate() && !this.smsError) {
        this.postDailyText();
      }
    },

    postDailyText() {
      const campaignCode = this.selectedCountry === "Nigeria" ? "YD" : "PISI";

      const now = Date.now();

      const nowDate = getDate(now);
      const nowTime = getTime(now);

      this.$emit(
        "postDailyText",
        this.sms,
        // this.immediate_schedule ? null : this.date, // Pass null if immediate schedule is true
        // this.immediate_schedule ? null : this.selectedHour, // Pass null if immediate schedule is true
        this.immediate_schedule ? nowDate : this.date, // Pass null if immediate schedule is true
        this.immediate_schedule ? nowTime : this.selectedHour,
        campaignCode,
        this.campaignName,
        this.immediate_schedule, // Send the immediate_schedule flag
        this.all_subscribers
      );
    },

    closeModal() {
      this.$refs.form.reset();
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.customButton {
  width: max-content;
  padding: 4px;
  margin-left: auto;
}
.my-4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-2 {
  margin-top: 3px;
  margin-bottom: 3px;
}
</style>
