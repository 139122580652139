<!-- <template>
  <v-pagination
    ref="pagination"
    :value="page"
    :length="total"
    @input="handleClick"
  ></v-pagination>
</template>


<script>
export default {
  props: {
    page: {
      type: Number,
    },
    total: {
      type: Number,
    },
  },

  methods: {
    handleClick(e) {
      this.$emit("setPagination", e);
    },
  },
};
</script> -->

<template>
  <div>
    <v-pagination
      v-model="displayedPage"
      :length="total"
      :items-per-page="itemsPerPage"
      @input="handlePageChange"
      :total-visible="5"
      class="custom-pagination"
    ></v-pagination>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      displayedPage: this.page,
      itemsPerPage: 1, // Assuming 1 item per page
      pages: [],
    };
  },
  computed: {
    showLeftDots() {
      return this.displayedPage > 3;
    },
    showRightDots() {
      return this.displayedPage < this.total - 2;
    },
  },
  methods: {
    calculatePages() {
      const pages = [];
      const page = this.displayedPage;
      if (this.total <= 5) {
        for (let i = 1; i <= this.total; i++) {
          pages.push(i);
        }
      } else {
        const startPage = Math.max(1, page - 2);
        const endPage = Math.min(this.total, page + 2);

        if (startPage > 1) {
          pages.push(1);
          if (this.showLeftDots) pages.push('...');
        }

        for (let i = startPage; i <= endPage; i++) {
          pages.push(i);
        }

        if (this.showRightDots) {
          if (endPage < this.total) pages.push('...');
          pages.push(this.total);
        }
      }

      return pages;
    },
    setPage(page) {
      if (page !== '...') {
        this.displayedPage = page;
        this.$emit("setPagination", page);
        this.pages = this.calculatePages();
      }
    },
    handlePageChange(page) {
      this.setPage(page);
    },
  },
  watch: {
    page(newPage) {
      this.displayedPage = newPage;
      this.pages = this.calculatePages();
    },
    total() {
      this.pages = this.calculatePages();
    },
  },
  mounted() {
    this.pages = this.calculatePages();
  },
};
</script>

<style>
.custom-pagination {
  margin-bottom: 1rem;
}
.pagination-numbers {
  display: flex;
  align-items: center;
}
.page-number {
  cursor: pointer;
  margin: 0 5px;
}
.page-number.active {
  font-weight: bold;
}
.dots {
  margin: 0 5px;
}
</style>