<template>
  <v-app>
    <v-main class="hidden-md-and-down">
      <v-row style="height:100vh">
        <v-col col="0" md="0" sm="0" lg="0">
          <NavBar :isMobile="false" ref="nav" :user="user" />
        </v-col>
        <v-col cols="12" md="11" sm="11" lg="11">
          <AppBar
            @showDrawer="showDrawer"
            :user="user"
            @logout="logout"
          />
          <div :class="isActivityPage ? 'activity-layout' : 'dashboard-layout'">
            <slot></slot>
          </div>
        </v-col>
      </v-row>
    </v-main>
    <!-- mobile screen -->
    <v-main class="hidden-lg-and-up">
      <AppBar @showDrawer="showDrawer" :user="user" />
      <NavBar :isMobile="true" ref="nav" :user="user" />
      <div class="dashboard-layout">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>
    
    
<script>
import AppBar from "./appbar/AppBar.vue";
import NavBar from "./navbar/NavBar.vue";
import "./DashboardLayout.css";
import { ADMINDETAILS } from "@/constants/constants";
import { isPageActivities } from "@/utils/formatter";

export default {
  components: {
    AppBar,
    NavBar,
  },

  computed: {
    isActivityPage() {
      return isPageActivities();
    },
  },

  data() {
    return {
      user: ADMINDETAILS,
    };
  },
  methods: {
    showDrawer() {
      this.$refs.nav.showDrawer();
    },

    logout(){
      this.$refs.nav.handleLogout()
    }
  },
};
</script>