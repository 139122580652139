import { handleError, handleSuccess } from "@/utils/handleResponse";
import healthTrackerService from "@/services/HealthTracker/healthTrackerService";

export default {
  namespaced: true,
  state: {
    loading: false,
    page: 1,
    limit: 10,
    sort: "ASC",
    trackerLabTest: [],
    totalpages: 1,
    followLoading:false,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_FOLOW_LOADING(state, value) {
        state.followLoading = value;
      },
    SET_LABS_TEST(state, value) {
      state.trackerLabTest = value;
    },
    SET_PAGE(state, value) {
      state.page = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalpages = value;
    },
  },
  actions: {
    fetchHealthTracker: async ({ commit },{status}) => {
      try {
        commit("SET_LOADING", true);
        const response = await healthTrackerService.fetchHealthTrackertest(
          `/getHealthTrackerTest?page=1&status=${status}`
        );
        commit("SET_LOADING", false);
        console.log("response.data=>", response.data);
        commit("SET_TOTAL_PAGES", response.data.totalPages);
        commit("SET_LABS_TEST", response.data.content);
      } catch (error) {
        commit("SET_LOADING", false);
        commit("SET_LABS_TEST", []);
        commit("SET_TOTAL_PAGES", 1);
        handleError(error.message);
      }
    },
    handlePagination: async ({ commit },{page,status}) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_PAGE",page);
        const response = await healthTrackerService.fetchHealthTrackertest(
          `/getHealthTrackerTest?page=${page}&status=${status}`
        );
        commit("SET_LABS_TEST", response.data.content);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LABS_TEST", []);
        commit("SET_LOADING", false);
      }
    },
    // New action to create an article
    createFollowUp: async ({ commit }, value) => {
      try {
        commit("SET_FOLOW_LOADING", true);
        const response = await healthTrackerService.updateFollowUp("updateHealthTrackarStatus",value);
        console.log("response",response)
        if(response.status==200){
          handleSuccess("Update Successfully");
        }else{
          handleError(response.data.error.message);
        }
        commit("SET_FOLOW_LOADING", false);
        // Call onSuccess callback if provided   
     
       
      } catch (error) {
        commit("SET_FOLOW_LOADING", false);
        handleError(error.message);

        // Call onError callback if provided
       
      }
    },


    updateRefundStatus: async ({ commit }, value) => {
      try {
        commit("SET_FOLOW_LOADING", true);
        const response = await healthTrackerService.updateRefundStatus("updateRefundStatus",value);
        console.log("response",response)
        if(response.status==200){
          handleSuccess("Refund Payment Successfully");
        }else{
          handleError(response.data.error.message);
        }
        commit("SET_FOLOW_LOADING", false);
        // Call onSuccess callback if provided   
     
       
      } catch (error) {
        commit("SET_FOLOW_LOADING", false);
        handleError(error.message);

        // Call onError callback if provided
       
      }
    },

  },
};
