<template>
  <DashboardLayout>
    <div>
      <h2>Article</h2>
      <div class="my-2">
        <!-- <v-text-field
          prepend-inner-icon="mdi-magnify"
          v-model="search"
          outlined
          dense
          style="border-radius: 10px"
          label="Search"
          single-line
        /> -->
      </div>
      <CreateArticle/>

      <!-- <TrackerHeader :title="'Top Doctors'" /> -->
      <!-- <div class="admin-track-table">
        <TrackerTable :columns="trackercolumn">
          <tr class="table-row" v-for="(row, i) in trackertabledata" :key="i">
            <td>
              <v-avatar class="mr-2"
                ><img src="@/assets/subadmin.svg" /> </v-avatar
              >{{ row.name }}
            </td>
            <td>{{ row.speciality }}</td>
            <td>{{ row.numOfConsultations }}</td>
          </tr>
        </TrackerTable>
      </div> -->

      <!-- <div class="my-6">
      <TrackerHeader :title="'Least Favourite Doctor'" />
      <div class="admin-track-table">
        <TrackerTable :columns="trackercolumn">
          <tr class="table-row" v-for="(row, i) in trackertabledata" :key="i">
            <td>
              <v-avatar class="mr-2"
                ><img src="@/assets/subadmin.svg" /> </v-avatar
              >{{ row.name }}
            </td>
            <td>{{ row.speciality }}</td>
            <td>{{ row.numOfConsultations }}</td>
          </tr>
        </TrackerTable>
      </div> -->
    </div>
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import CreateArticle from "@/components/Articles/CreateArticles/CreateArticle.vue"
export default {
  components: {
    DashboardLayout,
    CreateArticle
  },

  data() {
    return {
    
    };
  },
};
</script>