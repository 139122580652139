<template>
  <v-container>
    <v-card class="pa-5 custom-card" outlined>
      <v-form ref="form">
        <v-row>
          <!-- Title Field (conditionally shown) -->
          <v-col cols="12" md="6">
            <v-text-field
              v-model="title"
              label="Title"
              outlined
              dense
              :color="BRANDCOLOR"
              hide-details="auto"
              class="custom-input"
              :error-messages="errors.title"
            />
          </v-col>

          <!-- Category Field -->
          <v-col cols="12" md="6">
            <v-select
              v-model="category"
              :items="categories"
              label="Category"
              dense
              outlined
              :color="BRANDCOLOR"
              hide-details="auto"
              class="custom-input"
              :error-messages="errors.category"
            />
          </v-col>
        </v-row>

        <!-- Image Upload (conditionally shown) and Sub-Category Field (conditionally shown) -->
        <v-row>
          <v-col cols="12" md="6">
            <v-btn
              :color="BRANDCOLOR"
              outlined
              block
              @click="triggerFileUpload"
              class="mb-3"
            >
              Upload Image
            </v-btn>
            <input
              type="file"
              ref="fileInput"
              @change="handleImageChange"
              accept="image/*"
              class="d-none"
            />
            <div class="image-preview-container">
              <v-img
                v-if="imagePreview"
                :src="imagePreview"
                max-width="100%"
                max-height="200px"
                contain
                class="mt-3 image-preview"
              />
              <v-icon v-if="imagePreview" @click="removeImage" class="remove-icon">
                mdi-close
              </v-icon>
            </div>
          </v-col>

          <v-col cols="12" md="6" v-if="showSubCategory">
            <v-select
              v-model="sub_category"
              :items="subCategories"
              label="Sub-Category"
              dense
              outlined
              :color="BRANDCOLOR"
              hide-details="auto"
              class="custom-input"
              :error-messages="errors.sub_category"
            />
          </v-col>
        </v-row>

        <!-- Description Field -->
        <v-row>
          <v-col cols="12">
            <!-- <v-textarea
              v-model="description"
              label="Description"
              outlined
              dense
              :color="BRANDCOLOR"
              rows="4"
              :counter="1500"
              maxlength="1500"
              hide-details="auto"
              class="custom-input"
              :error-messages="errors.description"
            /> -->
            <div>
              <quill-editor v-model="description" :options="editorOptions"></quill-editor>
            </div>
          </v-col>
        </v-row>

        <!-- Submit Button -->
        <v-row>
          <v-col cols="12">
            <PrimaryButton
              :loading="loading"
              :color="BRANDCOLOR"
              :block="true"
              :large="true"
              @click="submitForm"
              :disabled="loading || description == ''"
            >
              Upload Article
            </PrimaryButton>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import { BRANDCOLOR } from "@/constants/constants";
import { ADMINDETAILS } from "@/constants/constants";
import { mapState } from "vuex";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: { PrimaryButton, quillEditor },
  data: () => ({
    title: "",
    description: "",
    thumbnails: null,
    singleArticle: null,
    imagePreview: "",
    existingImageUrl: null,
    category: "",
    sub_category: "",
    categories: [
      "Health Facts",
      "Health Articles",
      "Community Health",
      "Betacare Guide",
      "News",
    ],
    user: ADMINDETAILS,
    subCategories: [],
    BRANDCOLOR,
    errors: {}, // Store validation errors
    editorOptions: {
      theme: "snow",
    },
    articleId: null, // To store the article ID from the URL
    isUpdating: false, // To check if we are in update mode
  }),
  computed: {
    showSubCategory() {
      return [
        "Community Health",
        "Betacare Guide",
        "Health Facts",
        "Health Articles",
      ].includes(this.category);
    },
    ...mapState("article", {
      loading: (state) => state.loading,
    }),
  },
  watch: {
    title() {
      if (this.title) {
        this.errors.title = "";
      }
    },
    description() {
      if (this.description) {
        this.errors.description = "";
      }
    },
    sub_category() {
      if (this.sub_category) {
        this.errors.sub_category = "";
      }
    },
    thumbnails() {
      if (this.thumbnails) {
        this.errors.thumbnails = "";
      }
    },
    category(newCategory) {
      this.updateSubCategories(newCategory);
      this.resetFieldsForCategory(newCategory);
    },
  },
  methods: {
    removeImage() {
      this.imagePreview = "";
      this.thumbnails = null;
    },

    async fetchArticle() {
      try {
        const article = await this.$store.dispatch(
          "article/getSingleArticle",
          this.articleId
        );
        console.log("article====>", article);
        this.singleArticle = article;
        this.title = article.title;
        this.description = article.description;
        this.category = article.category;
        this.sub_category = article.sub_category;
        this.thumbnails = article.thumbnails;
        this.existingImageUrl = article.thumbnails;
        this.imagePreview = article.thumbnails; // Assuming you have the URL of the image stored in `thumbnails`
        this.isUpdating = true; // Switch to update mode
      } catch (error) {
        console.error("Failed to fetch article:", error);
      }
    },
    validateForm() {
      this.errors = {};
      if (!this.title) {
        this.errors.title = "Title is required";
      }
      if (!this.category) {
        this.errors.category = "Category is required";
      }
      if (this.showSubCategory && !this.sub_category) {
        this.errors.sub_category = "Sub-category is required";
      }
      if (!this.description) {
        this.errors.description = "Description is required and must be under 1500 words";
      }
      return Object.keys(this.errors).length === 0;
    },
    async submitForm() {
      if (this.validateForm()) {
        let data = "";
        if (this.isUpdating) {
          data = {
            id: this.articleId,
            title: this.title,
            description: this.description,
            existingImageUrl:this.existingImageUrl,
            thumbnails: this.thumbnails,
            category: this.category,
            sub_category: this.sub_category,
            user_id: this.singleArticle.author.user_id,
            role: this.singleArticle.author.role,
            name: `${this.singleArticle.userDetail.firstName}  ${this.singleArticle.userDetail.lastName}`,
            likes: 0,
            comment_user: 0,
            status: this.singleArticle.status,
            device_token: this.singleArticle.device_token,
          };
        } else {
          data = {
            title: this.title,
            description: this.description,
            thumbnails: this.thumbnails,
            category: this.category,
            sub_category: this.sub_category,
            user_id: this.user.id,
            role: "admin",
            likes: 0,
            comment_user: 0,
            status: "Accept",
            device_token: "",
          };
        }

        if (this.isUpdating) {
          await this.$store.dispatch("article/updateArticle", data);
          this.$router.push("/all-article");
        } else {
          // Create a new article
          await this.$store.dispatch("article/createArticle", data);
          this.$router.push("/all-article");
        }
        this.clearField();
      }
    },
    clearField() {
      this.title = "";
      this.existingImageUrl=null
      this.description = "";
      this.thumbnails = null;
      this.imagePreview = "";
      this.category = "";
      this.sub_category = "";
    },
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    handleImageChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.thumbnails = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    updateSubCategories(newCategory) {
      if (newCategory === "Community Health") {
        this.subCategories = [
          "Community Health Initiative Programs",
          "Customer Stories",
          "Upcoming Events",
          "News",
        ];
      } else if (newCategory === "Betacare Guide") {
        this.subCategories = [
          "Onboarding Process",
          "Consultation Process",
          "Appointment Booking",
          "Wallet Function",
          "Drug Order",
          "BetaPedia",
        ];
      } else if (["Health Facts", "Health Articles"].includes(newCategory)) {
        this.subCategories = [
          "Preventive Care",
          "Common Conditions",
          "Chronic Conditions",
          "Mental Health",
          "Men's Health",
          "Women's Health",
          "Sexual Health",
          "Children's Health",
          "Nutrition and Diet",
        ];
      } else {
        this.subCategories = [];
      }
    },
    resetFieldsForCategory(newCategory) {
      if (newCategory && !this.isUpdating) {
       

        if (!this.showSubCategory) {
          this.sub_category = "";
        }
        if (!this.showImageUpload) {
          this.thumbnails = null;
          this.imagePreview = "";
        }
        if (this.category) {
          this.errors.category = "";
        }
      }
    },
  },
  mounted() {
    this.articleId = this.$route.params.id; // Get the ID from the URL
    if (this.articleId) {
      this.fetchArticle(); // Fetch the article data if an ID is present
    }
  },
};
</script>

<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

.custom-input input {
  border-color: var(--v-primary-base) !important;
}

.v-text-field .v-input__control {
  border-bottom-width: 2px !important;
}

.v-select .v-input__control {
  border-bottom-width: 2px !important;
}

.image-preview-container {
  position: relative;
  height:"200px";
  width:"300px";
  display:"flex";
   justify:"center"
}
.image-preview {
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: red; /* Change color as needed */
  background: white; /* Optional: add background to make it stand out */
  border-radius: 50%; /* Optional: round the icon */
  padding: 4px; /* Optional: adjust padding */
}
</style>
