<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="closeModal" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <h2 class="py-3 text-center my-4">Follow Up Message</h2>

    <v-form ref="form" v-model="formValid">
      <!-- Email Subject -->
      <div class="my-2">Title</div>
      <div class="my-4">
        <v-text-field
          v-model="title"
          outlined
          dense
          :rules="[(v) => !!v || 'Title is required']"
          required
        />
      </div>
      <div class="my-4">
        <v-textarea
          v-model="description"
          label="Description"
          outlined
          dense
          :color="BRANDCOLOR"
          rows="4"
          :counter="1500"
          maxlength="1500"
          hide-details="auto"
          class="custom-input"
        />
      </div>
      <div class="my-4">
        <v-select
          v-model="status"
          :items="statusValues"
          label="Status"
          dense
          outlined
          hide-details="auto"
          class="custom-input"
        />
      </div>
    </v-form>

    <!-- Submit Button -->
    <div class="my-6">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        @click="validateAndSubmit"
      >
        Save
      </PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },

  props: {
    loading: {
      type: Boolean,
    },
  },

  data() {
    return {
      BRANDCOLOR,
      title: "",
      description: "",
      status: "not fullfilled",
      statusValues: ["not fulfilled", "fulfilled"],
      titleError: false, // to track the error state of Quill Editor
      formValid: false,
    };
  },

  methods: {
    validateAndSubmit() {
      // Validate the Quill Editor content
      this.titleError = this.title.trim() === "";

      // Check if the form is valid
      if (this.$refs.form.validate() && !this.smsError) {
        // Submit the form if validation passes
        this.postFollow();
      }
    },

    postFollow() {
      this.$emit("postFollowUp", this.title, this.description, this.status);
    },

    closeModal() {
      this.$refs.form.reset();
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
