<template>
  <DashboardLayout>
    <div>
      <!-- Event Filter and Create Button -->
      <div class="my-6 filter-container">
        <div>Event</div>
        <div>
          <PrimaryButton @click="addNewEvent" :color="BRANDCOLOR" :outlined="true">
            <v-icon small class="mr-2">mdi-plus-circle-outline</v-icon> Create Event
          </PrimaryButton>
        </div>
      </div>

      <!-- Admin Table for Events -->
      <AdminTable :columns="tableColumns" :loading="loading">
        <tr class="table-row" v-for="(event, i) in eventMessages" :key="i">
          <td>{{ event?.eventDate }}</td>
          <td>{{ event?.eventTitle }}</td>
          <td>
            <v-btn icon @click="showEventDetails(event)" color="primary">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn icon @click="editEvent(event)" color="secondary">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </td>
        </tr>
      </AdminTable>

      <!-- Pagination -->
      <div class="my-8 d-flex justify-space-between">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>

      <!-- Create or Edit Event Dialog -->
      <v-dialog v-model="showEventModal" max-width="600px" :key="modalKey">
        <AddNewEvent
          ref="addNewEvent"
          @closeModal="showEventModal = false"
          :loading=" saveLoading"
          :isEditing="isEditing"
          :event="selectedEvent"
          @postEvent="handleEventSubmit"
        />
      </v-dialog>

      <!-- Event Detail Dialog -->
      <v-dialog v-model="showDetailsModal" max-width="580px">
        <EventDetail
          :contentData="selectedEvent"
          @closeModal="showDetailsModal = false"
        />
      </v-dialog>

      <!-- Overlay Loader -->
      <!-- <overlay-loader :loading="loading" :description="'Loading...'" /> -->
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import PrimaryButton from "@/elements/Buttons/PrimaryButton.vue";
import AddNewEvent from "@/elements/Modals/AddNewEvent.vue";
import EventDetail from "@/elements/Modals/EventDetail.vue";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import eventService from "@/services/Event/event"; // Assuming there's a service for events

export default {
  components: {
    DashboardLayout,
    AdminTable,
    PrimaryButton,
    AddNewEvent,
    EventDetail,
    TablePagination,
  },
  data() {
    return {
      COLUMNS: ["Event Date", "Event Name", "Actions"],
      saveLoading: false,
      showEventModal: false,
      showDetailsModal: false,
      isEditing: false,
      selectedEvent: null,
      modalKey: 0,
    };
  },
  computed: {
    ...mapState("event", {
      loading: (state) => state.loading,
      eventMessages: (state) => state.eventMessages,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
    tableColumns() {
      return this.COLUMNS;
    },
  },
  created() {
    this.fetchEvents();
  },
  methods: {
    async fetchEvents() {
      await this.$store.dispatch("event/fetchEvent");
    },
    setPagination(page) {
      this.$store.dispatch("event/handlePagination", { page });
    },
    addNewEvent() {
      this.isEditing = false;
      this.selectedEvent = null;
      this.modalKey++;
      this.showEventModal = true;
    },
    editEvent(event) {
      this.isEditing = true;
      this.selectedEvent = event;
      this.modalKey++;
      this.showEventModal = true;
    },
    async handleEventSubmit(eventData) {
      console.log("eventData==>", eventData);
      try {
        this.saveLoading = true;
        if (this.isEditing) {
          await eventService.updateEvent("updateEvent",eventData);
           handleSuccess("Create Event  Successfully");
        } else {
          await eventService.createEvent("createEvent",eventData);
           handleSuccess("Update Event  Successfully");
        }
        this.fetchEvents();
        this.showEventModal = false;
        this.saveLoading = false;
      } catch (error) {
        this.saveLoading = false;
        handleError(error.message);
      } finally {
        this.saveLoading = false;
      }
    },
    showEventDetails(event) {
      this.selectedEvent = event;
      this.showDetailsModal = true;
    },
  },
};
</script>

<style scoped>
.admin-table-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
}

.table-row td {
  padding: 12px;
  white-space: nowrap;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.no-article-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Increase the font size */
  height: 100px; /* Optional: adjust height to ensure vertical centering */
  font-weight: bold; /* Optional: make the text bold */
  color: #555; /* Optional: change the text color */
}

.filter-container {
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
}
</style>
