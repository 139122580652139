import { api, azureApi } from "@/api/api";
import { HEADERS } from "@/constants/constants";

export default {
  async fetchDailyText(url) {
    return azureApi.get(url);
  },
  async fetchMessageDetail(url) {
    return azureApi.get(url);
  },

  async updateDailyText(url, data) {
    return api.put(url, data, {
      headers: HEADERS,
    });
  },

  async postDailyBulkSMS(url, data) {
    return azureApi.post(url, data);
  },

  async postBulkEMAIL(url, data) {
    return azureApi.post(url, data);
  },

  async manualSendDailyText(url, data) {
    return api.post(url, data, {
      headers: HEADERS,
    });
  },

  async deleteDailyText(url) {
    return api.delete(url, {
      headers: HEADERS,
    });
  },
};
