<template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <!-- <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
    </div>

    <div>
      <h2 class="my-3 text-center font-weight-bold">Article's Details</h2>

      <v-row>
        <v-col cols="12" class="mb-2" v-if="article?.title">
          <div class="font-semibold grey--text">Title</div>
          <div class="text-lg" :class="{ 'grey--text': !article?.title }">
            {{ article?.title || "No Title Provided" }}
          </div>
        </v-col>

        <v-col cols="6" class="mb-2" v-if="article?.category">
          <div class="font-semibold grey--text">Category</div>
          <div :class="{ 'grey--text': !article?.category }">
            {{ article?.category }}
          </div>
        </v-col>

        <v-col cols="6" class="mb-2" v-if="article?.sub_category">
          <div class="font-semibold grey--text">Subcategory</div>
          <div :class="{ 'grey--text': !article?.sub_category }">
            {{ article?.sub_category }}
          </div>
        </v-col>

        <v-col cols="12" v-if="article?.thumbnails !=''" class="mb-2">
          <div class="font-semibold mb-2 grey--text">Thumbnail Preview</div>
          <div class="image-preview-container">
            <v-img
              :src="article.thumbnails"
              width="100%"
              max-height="200px"
              alt="Article Thumbnail"
              class="image-preview"
            />
          </div>
        </v-col>

        <v-col cols="12" class="mb-2">
          <div class="font-semibold grey--text">Description</div>
          <div
            :class="{ 'grey--text': !article?.description }"
            v-html="article?.description"
          ></div>
        </v-col>
      </v-row>
    </div>

    <div v-if="article?.status == 'Pending'" class="my-3">
      <PrimaryButton
        :color="'#FFE6E6'"
        :class="'red--text'"
        :large="true"
        @click.native="rejectRequest"
      >
        Reject
      </PrimaryButton>
      <PrimaryButton
        :large="true"
        :color="'#E9EEF9'"
        :class="'blue--text ml-5'"
        @click.native="acceptRequest"
      >
        Accept
      </PrimaryButton>
    </div>
    <overlay-loader :loading="loading" />
  </v-card>
</template>
<script>
import { handleError, handleSuccess } from "@/utils/handleResponse";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import articleService from "@/services/Article/articleService";
import OverlayLoader from "../Loader/OverlayLoader.vue";

export default {
  components: {
    PrimaryButton,
    OverlayLoader,
  },
  props: {
    article: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: "All",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    async acceptRequest() {
      this.loading = true;
      try {
        const response = await articleService.updateArticleStatus(
          `update-blog-status/${this.article.id}`,
          {
            action: "Approved",
            status: "Accept",
            deviceToken: this.article.device_token,
            name: `${this.article.userDetail.firstName} ${this.article.userDetail.lastName}`,
            role: this.article.author.role,
          }
        );
        handleSuccess(response.data?.message);
        this.loading = false;
        this.$emit("closeModal");
        this.$store.dispatch("article/fetchArticleRequests", this.status);
      } catch (error) {
        handleError(error.message);
      }
      this.loading = false;
    },

    async rejectRequest() {
      this.loading = true;
      try {
        const response = await articleService.updateArticleStatus(
          `update-blog-status/${this.article.id}`,
          {
            action: "Reject",
            status: "Reject",
            deviceToken: this.article.device_token,
            name: `${this.article.userDetail.firstName} ${this.article.userDetail.lastName}`,
            role: this.article.author.role,
          }
        );
        handleSuccess(response?.data?.message);
        this.loading = false;
        this.$emit("closeModal");
        this.$store.dispatch("article/fetchArticleRequests", this.status);
      } catch (error) {
        handleError(error.message);
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}

.image-preview-container {
  height: "200px";
  width: "200px";
  display: "flex";
  justify: "center";
}
.image-preview {
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.button-grid {
  display: flex;
  justify-content: space-between;
}
</style>
