
import doctorConsultationService from "@/services/DoctorConsultation/doctorConsultationService";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    loading: false,
    page: 1,
    limit: 10,
    selectedConsultation: null,
    sort: "ASC",
    doctorConsultations: [],
    totalpages: 1,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },

   
    SET_DOCTOR_CONSULT(state, value) {
      state.doctorConsultations = value;
    },
    SET_PAGE(state, value) {
      state.page = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalpages = value;
    },
    setSelectedConsultation(state, consultation) {
      state.selectedConsultation = consultation;
    },
  },
  actions: {
    fetchDoctorConsultation: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        const response = await doctorConsultationService.fetchDoctorConsultation(
          `getDoctorConsultantDetail?page=1`
        );

        commit("SET_DOCTOR_CONSULT", response.data.content)
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    handlePagination: async ({ commit },{page}) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_PAGE", page);
        const response = await doctorConsultationService.fetchDoctorConsultation(
          `getDoctorConsultantDetail?page=${page}`
        );
        commit("SET_DOCTOR_CONSULT", response.data.content);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_DOCTOR_CONSULT", []);
        commit("SET_LOADING", false);
      }
    },
    selectConsultation({ commit }, consultation) {
      commit('setSelectedConsultation', consultation);
    },
  },
};
