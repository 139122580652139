import articleService from "@/services/Article/articleService";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import firebase from "@/config/firebase";
import { v4 as uuidv4 } from "uuid";

export default {
  namespaced: true,
  state: {
    loading: false,
    page: 1,
    limit: 10,
    sort: "ASC",
    articlerequests: [],
    totalpages: 1,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_ARTICLE_REQUESTS(state, value) {
      state.articlerequests = value;
    },
    SET_PAGE(state, value) {
      state.page = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalpages = value;
    },
  },
  actions: {
    fetchArticleRequests: async ({ commit },status) => {
      try {
        commit("SET_LOADING", true);
        const response = await articleService.fetchArticleRequests(
          `/getBlogPending?page=1&status=${status}`
        );
        commit("SET_LOADING", false);
        console.log("response.data=>", response.data);
        commit("SET_TOTAL_PAGES", response.data.totalPages);
        commit("SET_ARTICLE_REQUESTS", response.data.content);
      } catch (error) {
        commit("SET_LOADING", false);
        commit("SET_ARTICLE_REQUESTS", []);
        commit("SET_TOTAL_PAGES", 1);
        handleError(error.message);
      }
    },
    handlePagination: async ({ commit },{page,status}) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_PAGE", page);
        const response = await articleService.fetchArticleRequests(
          `/getBlogPending?page=${page}&status=${status}`
        );
        commit("SET_ARTICLE_REQUESTS", response.data.content);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_ARTICLE_REQUESTS", []);
        commit("SET_LOADING", false);
      }
    },
    // New action to create an article
    createArticle: async ({ commit }, value) => {
      try {
        commit("SET_LOADING", true);
        // Upload image to Firebase
        let imageUrl=""
        if(value.thumbnails !=null){
          const storageRef = firebase.storage().ref();
          const imageRef = storageRef.child(`articles/${value?.category}/${uuidv4()}_${value.thumbnails.name}`);
          const snapshot = await imageRef.put(value.thumbnails);
          imageUrl = await snapshot.ref.getDownloadURL();
        }
        value.thumbnails = imageUrl;
        const response = await articleService.createArticle("create-blog",value);
        console.log("response",response)
        if(response.status==200){
          handleSuccess("Create Article Successfully");
        }else{
          handleError(response.data.error.message);
        }
        commit("SET_LOADING", false);
        // Call onSuccess callback if provided   
     
       
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);

        // Call onError callback if provided
       
      }
    },
    updateArticle: async ({ commit }, value) => {
      try {
        commit("SET_LOADING", true);
        // let selectedImage="https://firebasestorage.googleapis.com/v0/b/betacare-a27b2.appspot.com/o/betalogo.jpeg?alt=media&token=5d09e26d-0ff1-4109-b045-38c3996e0b04"
        let imageUrl = value.thumbnails==null ? "": value.thumbnails ;
    
        // Check if a new image is being uploaded
        if (value.thumbnails?.name) {
          // Delete the old image if it exists
          if (value?.existingImageUrl != null) {
            const oldImageRef = firebase.storage().refFromURL(value.existingImageUrl);
            await oldImageRef.delete();
          }
          
          // Upload the new image to Firebase
          const storageRef = firebase.storage().ref();
          const imageRef = storageRef.child(`articles/${value?.category}/${uuidv4()}_${value.thumbnails.name}`);
          const snapshot = await imageRef.put(value.thumbnails);
          imageUrl = await snapshot.ref.getDownloadURL();
        }
    
        // Update the value object with the new image URL
        value.thumbnails = imageUrl;
    
        // Update the article using the service
        const response = await articleService.updateArticle("updateBlog", value);
    
        if (response.status == 200) {
          handleSuccess("Article updated successfully");
        } else {
          handleError(response.data.error.message);
        }
    
        commit("SET_LOADING", false);
    
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },
    deleteArticle: async ({ commit }, id) => {
      try {
      
     
        const response = await articleService.deleteArticle(`deleteBlog/${id}`);
        console.log("response",response)
        if(response.status==200){
          handleSuccess("Delete Article Successfully");
        }else{
          handleError(response.data.error.message);
        }
        commit("SET_LOADING", false);
        // Call onSuccess callback if provided   
     
       
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);

        // Call onError callback if provided
       
      }
    },
    async getSingleArticle({ commit }, articleId) {
      try{
        const response = await articleService.getSingleArticle(`getBlogsById/${articleId}`);
        if(response.status==200){
         return response?.data
        }else{
          handleError(response.data.error.message);
          commit("SET_LOADING", false);
        }
      }catch(error){
    
        handleError("Something went wrong");
        commit("SET_LOADING", false);
      }
     
      
    },
  },
};
