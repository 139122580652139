<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";

import {
  ADMINTABLECOLUMNS,
  ADMINTABLEROWS,
} from "@/constants/constants";

export default {
  components: {
    DashboardLayout,
    AdminTable, 
  },

  data: () => ({
    columns: ADMINTABLECOLUMNS,
    admintabledata: ADMINTABLEROWS,
  }),
}

</script>

<template>
  <DashboardLayout>
    <div>
      <h2 class="admin-table-title mb-6">Patient’s Sign-up Log (10)</h2>
      <AdminTable :columns="columns">
        <tr class="table-row" v-for="(row, i) in admintabledata" :key="i">
          <td>{{ row.date }}</td>
          <td>{{ row.email }}</td>
          <td>{{ row.phone }}</td>
          <td>{{ row.time }}</td>
        </tr>
      </AdminTable>
    </div>
  </DashboardLayout>
</template>
