<template>
  <DashboardLayout>
    <pre>{{smsMessage}}</pre>
    <div>
      <div class="flex justify-between items-center mb-4">
        <div class="flex-gap">
          <v-btn icon @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <h2 class="admin-table-title">Sms Campaign Detail</h2>
        </div>
        <div class="filter-container">
          <v-select
            v-model="selectedStatus"
            :items="statuses"
            label="Filter by Status"
            dense
            outlined
          ></v-select>
        </div>
      </div>

      <v-card class="pt-4 px-6 pb-8 custom-card">
        <div class="d-flex justify-end"></div>

        <div>
          <!-- <h2 class="my-3 text-center font-weight-bold">Article's Details</h2> -->

          <v-row>
            <v-col cols="12" class="mb-2">
              <div class="font-semibold grey--text">Campaign name</div>
              <div class="text-lg">
                {{ smsMessage?.compaign_name }}
              </div>
            </v-col>
            <v-col cols="12" class="mb-2">
              <div class="font-semibold grey--text">Campaign Content</div>
              <div>
                {{ smsMessage?.message }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="mb-2">
              <div class="font-semibold grey--text">Time stamp</div>
              <div>
                {{ smsMessage?.scheduled_date }}
                {{ convertUtcToLocalHour(smsMessage?.scheduled_hour) }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="mb-2">
              <div class="font-semibold grey--text">Sent:</div>
              <div class="grey--tex">
                {{ sentCount }}
              </div>
            </v-col>
            <v-col cols="6" class="mb-2">
              <div class="font-semibold grey--text">Reject:</div>
              <div class="grey--tex">
                {{ rejectCount }}
              </div>
            </v-col></v-row
          >
        </div>
      </v-card>
      <div class="my-4">
        <AdminTable :columns="COLUMNS" :loading="loading">
          <tr class="table-row" v-for="(message, i) in filteredDailyText" :key="i">
            <td>{{ message?.patient?.firstName }} {{ message?.patient?.lastName }}</td>
            <td>{{ smsMessage?.service_name }}</td>
            <td>{{ message?.patient?.phoneNumber }}</td>
            <td>{{ message?.country }}</td>
            <!-- <td>{{ message?.status }}</td> -->
            <td :class="getMessageStatusClass(message?.status)">
              <v-icon v-if="message?.status === 'sent'" color="green"
                >mdi-check-circle</v-icon
              >
              <v-icon v-if="message?.status === 'reject'" color="red"
                >mdi-alert-circle</v-icon
              >
              {{ message?.status }}
            </td>
          </tr>
        </AdminTable>
      </div>

      <div v-if="filteredDailyText?.length > 0" class="my-4">
        <TablePagination
          :page="smsPage"
          :total="totalSmsPages"
          @setPagination="setPagination"
        />
      </div>
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";

export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
  },
  data: () => ({
    COLUMNS: ["Name", "Mobile Operator", "Phone Number", "Country", "Status"],
    menuloading: false,
    showdetails: false,

    statuses: ["All", "Sent", "Reject"],
    selectedStatus: "All", // Default to "All"
  }),

  computed: {
    ...mapState("dailytext", {
      loading: (state) => state.loading,
      smsDetail: (state) => state.smsDetail,
      smsPage: (state) => state.smsPage,
      smsMessage: (state) => state.smsMessage,
      totalSmsPages: (state) => state.totalSmsPages,
      sentCount: (state) => state.sentCount,
      rejectCount: (state) => state.rejectCount,
    }),
    filteredDailyText() {
      return this.smsDetail;
    },
  },
  watch: {
    selectedStatus(newStatus) {
      this.fetchSmsDetail(newStatus);
    },
  },
  created() {
    this.fetchSmsDetail(this.selectedStatus);
  },
  methods: {
    getMessageStatusClass(status) {
      if (status === "sent") return "status-paid";
      if (status === "reject") return "status-unpaid";
      return "";
    },
    convertUtcToLocalHour(utcTime) {
      if (utcTime) {
        const date = new Date();

        // Split the `utcTime` string into hours and minutes
        const [utcHour, utcMinutes] = utcTime.split(":").map(Number);

        // Set the UTC hours and minutes on the date object
        date.setUTCHours(utcHour, utcMinutes, 0, 0);

        // Get the local hours and minutes from the date object
        const localHour = date.getHours();
        const localMinutes = date.getMinutes();

        // Determine AM/PM
        const period = localHour >= 12 ? "PM" : "AM";
        const formattedHour = localHour % 12 || 12; // Convert 24-hour to 12-hour format
        const formattedMinutes = localMinutes.toString().padStart(2, "0"); // Pad single digits with 0

        // Return the formatted local time in HH:MM AM/PM format
        return `${formattedHour}:${formattedMinutes} ${period}`;
      }
      // Create a date object for today's date
    },
    goBack() {
      this.$router.push("/daily-text");
    },
    async fetchSmsDetail(status) {
      const id = this.$route.params.id;
      if (status == "Sent") {
        await this.$store.dispatch("dailytext/fetchSmsDetail", {
          status: "sent",
          id,
        });
      } else if (status == "Reject") {
        await this.$store.dispatch("dailytext/fetchSmsDetail", {
          status: "reject",
          id,
        });
      } else {
        await this.$store.dispatch("dailytext/fetchSmsDetail", { status: "All", id });
      }
    },
    setPagination(page) {
      const id = this.$route.params.id;
      if (status == "Sent") {
        this.$store.dispatch("dailytext/handleSmsPagination", {
          page,
          status: "sent",
          id,
        });
      } else if (status == "Reject") {
        this.$store.dispatch("dailytext/handleSmsPagination", {
          page,
          status: "reject",
          id,
        });
      } else {
        this.$store.dispatch("dailytext/handleSmsPagination", {
          page,
          status: "All",
          id,
        });
      }
    },
  },
};
</script>

<style scoped>
.admin-table-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  white-space: nowrap;
}
.count {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  white-space: nowrap;
}

.table-row td {
  padding: 12px;
  white-space: nowrap;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.status-paid {
  color: green;
  padding: 3px;
  border-radius: 8px;

  font-weight: bold;
}
.status-unpaid {
  color: red;
  padding: 3px;
  border-radius: 8px;

  font-weight: bold;
}
.no-article-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Increase the font size */
  height: 100px; /* Optional: adjust height to ensure vertical centering */
  font-weight: bold; /* Optional: make the text bold */
  color: #555; /* Optional: change the text color */
}

.filter-container {
  margin-bottom: 16px;
  max-width: 200px;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.flex-gap {
  display: flex;
}
</style>
