import {  adminApi } from "@/api/api";
// import { HEADERS } from "@/constants/constants";

export default {

  async fetchArticleRequests(url) {
    return adminApi.get(url);
  },
  async getSingleArticle(url) {
    return adminApi.get(url);
  },
  async createArticle(url,data) {
    return adminApi.post(url,data);
  },
  async updateArticle(url,data) {
    return adminApi.put(url,data);
  },
  async deleteArticle(url) {
    return adminApi.delete(url);
  },
  async updateArticleStatus(url, data) {
    return adminApi.put(url, data);
  },


 
};
