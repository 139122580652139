<template>
  <DashboardLayout>
    <div>
      <div class="flex justify-between items-center mb-4">
        <h2 class="admin-table-title">Consultation Detail</h2>
      </div>

      <AdminTable :columns="COLUMNS">
        <tr class="table-row" v-for="(message, i) in selectedConsultation" :key="i">
          <td>{{ `${message?.appointmentTime}` }}</td>
          <td>{{ message?.appointmentDate }}</td>
        <td class="status-complete">
            <v-icon color="green"
              >mdi-check-circle</v-icon
            >
          
            {{ message?.status }}
          </td>
    
        </tr>
      </AdminTable>
    </div>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";

export default {
    components: {
    DashboardLayout,
    AdminTable,
  },

   data: () => ({
    COLUMNS: [
      "Start and End Time",
      "Completion Date",
      "Consultation Status",
    ],
 
  }),

  computed: {
    ...mapState('doctorConsultation', {
      selectedConsultation: (state) => state.selectedConsultation,
    }),
  },
  created() {
    // If Vuex store is empty (like on page refresh), load from localStorage
    if (!this.selectedConsultation) {
      const storedConsultation = sessionStorage.getItem('selectedConsultation');
      if (storedConsultation) {
        const consultationData = JSON.parse(storedConsultation);

        // Re-populate the Vuex store with the data from localStorage
           this.$store.dispatch("doctorConsultation/selectConsultation",consultationData);
      } else {
        // If no data is found, navigate back to the previous page
        this.$router.push('/doctor-consultations');
      }
    }
  },
};
</script>

<style scoped>
.status-complete {
  color: green;
  padding: 3px;
  border-radius: 8px;

  font-weight: bold;
}
</style>
