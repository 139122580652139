<!-- <template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div>
      <div class="my">
      <h2 class=" text-center font-weight-bold">Labs Test Details</h2>
      </div>
      <v-row>
       <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Department:</div>
          <div class="text-lg">
            {{ labs?.testInformaion?.depName }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Name:</div>
          <div class="text-lg">
            {{ labs?.testInformaion?.testName }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Price:</div>
          <div class="text-lg">
            {{ labs?.testInformaion?.priceWithTax }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Type:</div>
          <div class="text-lg">
            {{ labs?.testType }}
          </div>
        </v-col>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">State:</div>
          <div class="text-lg">
            {{ labs?.state }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Address:</div>
          <div class="text-lg">
            {{ labs?.address }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Appointment Date:</div>
          <div class="text-lg">
            {{ labs?.appointmentDate }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Appointment Time:</div>
          <div class="text-lg">
            {{ labs?.appointmentTime }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Address:</div>
          <div class="text-lg">
            {{ labs?.address }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Gender:</div>
          <div class="text-lg">
            {{ labs?.gender }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Date of Birth:</div>
          <div class="text-lg">
            {{ labs?.dob }}
          </div>
        </v-col>



         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Alternative Phone Number:</div>
          <div class="text-lg">
            {{ labs?.alternativePhoneNumber }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Another Reference:</div>
          <div class="text-lg">
            {{ labs?.reference }}
          </div>
        </v-col>
         <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Date:</div>
          <div class="text-lg">
            {{ formatDate(labs?.createdAt) }}
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>
<script>
export default {
  components: {},
  props: {
    labs: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
    };
  },
  methods:{
      formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "Invalid Date";
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
  }
};
</script>
<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}
.my{
    margin-bottom:30px
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}


.showData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style> -->
<template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div>
      <div class="my">
        <h2 class="text-center font-weight-bold">Labs Test Details</h2>
      </div>
      <v-row>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Patient Name:</div>
          <div class="text-lg">{{ labs?.firstName }} {{ labs?.lastName }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Patient ID:</div>
          <div class="text-lg">{{ labs?.patientId }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Gender:</div>
          <div class="text-lg">{{ labs?.gender }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Date of Birth:</div>
          <div class="text-lg">{{ formatDate(labs?.dob) }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Phone Number:</div>
          <div class="text-lg">{{ labs?.phoneNumber }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Alternative Phone Number:</div>
          <div class="text-lg">{{ labs?.alternativePhoneNumber }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">State:</div>
          <div class="text-lg">{{ labs?.state }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Address:</div>
          <div class="text-lg">{{ labs?.address }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Appointment Date:</div>
          <div class="text-lg">{{ formatDate(labs?.appointmentDate) }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Appointment Time:</div>
          <div class="text-lg">{{ labs?.appointmentTime }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Payment Status:</div>
          <div class="text-lg">{{ labs?.paymentStatus }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Test Type:</div>
          <div class="text-lg">{{ labs?.testType }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Extra Payment:</div>
          <div class="text-lg">{{ labs?.extraPayment }}</div>
        </v-col>

        <!-- Loop through testInformation array to display test details -->
        <v-col cols="12" v-for="(test, index) in labs?.testInformaion" :key="index" class="mb-2 showDat">
          <div class="font-semibold grey--text">Test Name:</div>
          <div class="text-lg">{{ test?.testName }}</div>

          <div class="font-semibold grey--text">Department:</div>
          <div class="text-lg">{{ test?.depName }}</div>

          <div class="font-semibold grey--text">Test Price (with tax):</div>
          <div class="text-lg">{{ test?.priceWithTax }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Tracking Status:</div>
          <div class="text-lg">{{ labs?.trackingStatus }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Date Created:</div>
          <div class="text-lg">{{ formatDate(labs?.createdAt) }}</div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    labs: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) return "Invalid Date";

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
  },
};
</script>

<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}

.my {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}

.showData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
