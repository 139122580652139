<template>
  <DashboardLayout>
    <div>
      <h2>Create Bulk Email</h2>
      <div class="my-2">
   
      </div>
      <AddNewBulkSMS/>

     
    </div>
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";

import AddNewBulkSMS from "@/elements/Modals/AddNewBulkSMS.vue";
export default {
  components: {
    DashboardLayout,
    AddNewBulkSMS
  },

  data() {
    return {
    
    };
  },
};
</script>