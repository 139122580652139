import dailytextService from "@/services/DailyText/dailytextService";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    loading: false,
    dailytexts: [],
    smsDetail:[],
    page: 1,
    pageSms:1,
    limit: 10,
    rejectCount:0,
    sentCount:0,
    sort: "ASC",
    messageDetail: [],
    totalpages: 1,
    totalSmsPages :1,
    smsMessage:{},
    emailMessage:{}
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_DAILY_TEXT(state, value) {
      state.dailytexts = value
    },
    SET_MESSAGE_DETAIL(state, value) {
      state.messageDetail = value;
    },
    SET_EMAIL_MESSAGE(state, value) {
      state.emailMessage = value;
    },
    SET_SMS_DETAIL(state, value) {
      state.smsDetail = value;
    },
    SET_SMS_MESSAGE(state, value) {
      state.smsMessage = value;
    },
    SET_SENT_COUNT(state, value) {
      state.sentCount = value;
    },
    SET_REJECT_COUNT(state, value) {
      state.rejectCount = value;
    },
    SET_PAGE(state, value) {
      state.page = value;
    },
    SET_SMS_PAGE(state, value) {
      state.pageSms = value;
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalpages = value;
    },
    SET_TOTAL_SMS_PAGES(state, value) {
      state.totalSmsPages = value;
    },
  },
  actions: {
    fetchDailyTexts: async ({ commit }, url) => {
      try {
        commit("SET_LOADING", true);
        const response = await dailytextService.fetchDailyText(
          url
        );

        commit("SET_DAILY_TEXT", response.data.data)
        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },
    fetchMessageDetail: async ({ commit }, { status, id }) => {
      try {
        commit("SET_LOADING", true);
        const response = await dailytextService.fetchMessageDetail(
          `patient/api/email-messages-status?page=1&status=${status}&id=${id}`
        );
        commit("SET_LOADING", false);
        commit("SET_TOTAL_PAGES", response.data.totalPages);
        commit("SET_MESSAGE_DETAIL", response.data.content);
        commit("SET_EMAIL_MESSAGE", response.data.emailMessage);
        commit("SET_SENT_COUNT", response.data.sentCount);
        commit("SET_REJECT_COUNT", response.data.rejectCount);
      } catch (error) {
        commit("SET_LOADING", false);
        commit("SET_MESSAGE_DETAIL", []);
        commit("SET_EMAIL_MESSAGE",{});
        commit("SET_SENT_COUNT", 0);
        commit("SET_REJECT_COUNT",0);
        commit("SET_TOTAL_PAGES", 1);
        handleError(error.message);
      }
    },
    fetchSmsDetail: async ({ commit }, { status, id }) => {
      try {
        commit("SET_LOADING", true);
        const response = await dailytextService.fetchMessageDetail(
          `patient/api/sms-messages-status?page=1&status=${status}&id=${id}`
        );
        commit("SET_LOADING", false);
        commit("SET_TOTAL_SMS_PAGES", response.data.totalPages);
        commit("SET_SMS_DETAIL", response.data.content);
        commit("SET_SMS_MESSAGE", response.data.smsMessage);
        commit("SET_SENT_COUNT", response.data.sentCount);
        commit("SET_REJECT_COUNT", response.data.rejectCount);
      } catch (error) {
        commit("SET_LOADING", false);
        commit("SET_SMS_DETAIL", []);
        commit("SET_SMS_MESSAGE",{});
        commit("SET_SENT_COUNT", 0);
        commit("SET_REJECT_COUNT",0);
        commit("SET_TOTAL_SMS_PAGES", 1);
        handleError(error.message);
      }
    },

    handleSmsPagination: async ({ commit }, { page, status, id }) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_SMS_PAGE", page);
        const response = await dailytextService.fetchMessageDetail(
          `patient/api/sms-messages-status?page=${page}&status=${status}&id=${id}`
        );
        commit("SET_SMS_DETAIL", response.data.content);
        commit("SET_SENT_COUNT", response.data.sentCount);
        commit("SET_REJECT_COUNT", response.data.rejectCount);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_SMS_DETAIL", []);
        commit("SET_SENT_COUNT", 0);
        commit("SET_REJECT_COUNT",0);
        commit("SET_LOADING", false);
      }
    },
    handlePagination: async ({ commit }, { page, status, id }) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_PAGE", page);
        const response = await dailytextService.fetchMessageDetail(
          `patient/api/email-messages-status?page=${page}&status=${status}&id=${id}`
        );
        commit("SET_MESSAGE_DETAIL", response.data.content);
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_MESSAGE_DETAIL", []);
        commit("SET_LOADING", false);
      }
    },

  

  },
};
