<template>
  <v-card class="pt-4 px-6 pb-8">
    <div class="d-flex justify-end">
      <v-btn @click="closeModal" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <h2 class="py-3 text-center my-4">Health Tracker Service</h2>

    <v-form ref="form" v-model="formValid">
      <!-- Service Field (Required if paymentStatus != 'paid') -->
      <div class="my-4" v-if="paymentStatus == 'Pending'">
        <v-select
          v-model="service"
          :items="serviceValues"
          label="Service"
          :rules="[(v) => !!v || 'Service is required']"
          dense
          outlined
          hide-details="auto"
          class="custom-input"
        />
      </div>

      <!-- Status Field (Required if paymentStatus == 'paid') -->
      <div class="my-4" v-if="paymentStatus == 'In Progress'">
        <v-select
          v-model="status"
          :items="statusValues"
          label="Tracking Status"
          :rules="[(v) => !!v || 'Tracking status is required']"
          dense
          outlined
          hide-details="auto"
          class="custom-input"
        />
      </div>
    </v-form>

    <!-- Submit Button -->
    <div class="my-6">
      <PrimaryButton
        :loading="loading"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        @click="validateAndSubmit"
      >
        Save
      </PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";

export default {
  components: {
    PrimaryButton,
  },

  props: {
    loading: {
      type: Boolean,
    },
    paymentStatus: {
      type: String,
    },
  },

  data() {
    return {
      BRANDCOLOR,
      title: "",
      description: "",
      status: "",
      statusValues: ["Fullfilled", "Cancelled"],
      service: "",
      serviceValues: ["available", "not available"],
      formValid: false, // To track if the form is valid
    };
  },

  methods: {
    validateAndSubmit() {
      // Validate the form fields
      if (this.$refs.form.validate()) {
        // Submit the form if validation passes
        this.postFollow();
      }
    },
    postFollow() {
      if (this.service == "not available") {
        this.$emit("postFollowUp", "Cancelled", "Cancelled");
      } else if (this.service == "available") {
        this.$emit("postFollowUp", "In Progress", "In Progress");
      } else {
        this.$emit("postFollowUp", this.status, this.status);
      }
    },

    closeModal() {
      this.$refs.form.reset();
      this.$emit("closeModal");
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-2 {
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
