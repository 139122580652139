<template>
  <DashboardLayout>
    <div>
      <div class="flex justify-between items-center mb-4">
        <h2 class="admin-table-title">All Articles</h2>

        <div class="filter-container">
          <v-select
            v-model="selectedStatus"
            :items="statuses"
            label="Filter by Status"
            dense
            outlined
          ></v-select>
        </div>
      </div>

      <AdminTable :columns="COLUMNS" :loading="loading">
        <tr class="table-row" v-for="(article, i) in filteredArticles" :key="i">
          <td>{{ formatDate(article?.created_at) }}</td>
          <td>{{ article?.title }}</td>
          <td>{{ article?.category }}</td>
          <td>{{ article?.sub_category}}</td>
            <td>{{ article?.status}}</td>
          <td>
            <v-btn icon @click="showDetails(article)" color="primary">
              <v-icon>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="updateArticle(article)"
              color="primary"
              class="mx-2"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn icon @click="deleteArticle(article)" color="error">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </AdminTable>

      <div v-if="filteredArticles.length > 0" class="my-4">
        <TablePagination
          :page="page"
          :total="totalpages"
          @setPagination="setPagination"
        />
      </div>
      <!-- <div v-if="menuloading &&" class="no-article-found my-4">
        No Article Found
      </div> -->
    </div>

    <div>
      <v-dialog max-width="580px" v-model="showdetails">
        <ArticleRequest :article="article" :status="selectedStatus" @closeModal="closeModal" />
      </v-dialog>
    </div>

    <overlay-loader :loading="menuloading" :description="'Loading...'" />
  </DashboardLayout>
</template>
<script>
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import AdminTable from "@/elements/Tables/AdminTable.vue";
import { mapState } from "vuex";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";
import TablePagination from "@/elements/Tables/TablePagination.vue";
import ArticleRequest from "@/elements/Modals/ArticleRequest.vue";

export default {
  components: {
    DashboardLayout,
    AdminTable,
    OverlayLoader,
    TablePagination,
    ArticleRequest,
  },
  data: () => ({
    COLUMNS: ["Date", "Title", "Category", "Sub Category","Status", "Action"],
    menuloading: false,
    showdetails: false,
    article: {}, // Initialize article as an empty object
    statuses: ["All", "Pending", "Accept", "Reject"],
    selectedStatus: "All", // Default to "All"
  }),

  computed: {
    ...mapState("article", {
      loading: (state) => state.loading,
      articlerequests: (state) => state.articlerequests,
      page: (state) => state.page,
      totalpages: (state) => state.totalpages,
    }),
    filteredArticles() {
      return this.articlerequests;
    },
  },
  watch: {
    selectedStatus(newStatus) {
      this.fetchArticles(newStatus);
    },
  },
  created() {
    this.fetchArticles(this.selectedStatus);
  },
  methods: {
    async fetchArticles(status) {
      await this.$store.dispatch("article/fetchArticleRequests", status);
    },
    setPagination(page) {
      this.$store.dispatch("article/handlePagination", { page, status });
    },
    showDetails(article) {
      this.article = { ...article }; // Ensure a fresh copy of the article is used
      this.showdetails = true;
    },
    async deleteArticle(article) {
      await this.$store.dispatch("article/deleteArticle", article.id);
      this.fetchArticles(this.selectedStatus);
    },
    updateArticle(article) {
      this.$router.push(`/article/${article.id}`);
    },
    closeModal() {
      this.showdetails = false;
      this.article = {}; // Reset the article data when closing the modal
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      if (isNaN(date)) {
        return "Invalid Date";
      }

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatTime(dateString) {
      try {
        const date = new Date(dateString);

        if (isNaN(date)) {
          return "Invalid Time";
        }

        // Convert to local time if necessary
        const hours = date.getUTCHours(); // Using UTC hours
        const minutes = String(date.getUTCMinutes()).padStart(2, "0");
        const period = hours >= 12 ? "PM" : "AM";

        const formattedHours = hours % 12 || 12; // Convert to 12-hour format

        return `${formattedHours}:${minutes} ${period}`;
      } catch (error) {
        return "Invalid Time";
      }
    },
  },
};
</script>

<style scoped>
.admin-table-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
   white-space: nowrap;
}

.table-row td {
  padding: 12px;
   white-space: nowrap;
  
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.no-article-found {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px; /* Increase the font size */
  height: 100px; /* Optional: adjust height to ensure vertical centering */
  font-weight: bold; /* Optional: make the text bold */
  color: #555; /* Optional: change the text color */
}

.filter-container {
  margin-bottom: 16px;
  max-width: 200px;
}
</style>
