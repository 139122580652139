import { api } from "@/api/api";
import { HEADERS } from "@/constants/constants";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import { adminApi } from "../../api/api";

export default {
  async login(data) {
    try {
      const response = await adminApi.post("/login", {
        email: data.email,
        password: data.password,
        device_token:"sss"
      });
      console.log("response===>",response)
      if (response.data.token) {
        handleSuccess("Login Successful!. Redirecting...");
      }
      return response.data;
    } catch (error) {
      handleError(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },

  async fetchAdminDetails(url) {
    try {
      // const response = await api.get(url, {
      //   headers: {
      //     Authorization: "Bearer " + value.token,
      //   },
      // });
      const response = await adminApi.get(url);
      return response.data;
    } catch (error) {
      handleError(error.response.data.message);
      throw new Error(error.response.data.message);
    }
  },

  async updateProfile(url, data) {
    try {
      const response = await api.post(url, data, {
        headers: HEADERS,
      });
      return response.data;
    } catch (error) {
      handleError(error.message);
      throw new Error(error.message);
    }
  },
};
