import requestService from "@/services/Requests/requestService";
import { removeDuplicates } from "@/utils/formatter";
import { handleError } from "@/utils/handleResponse";

export default {
  namespaced: true,
  state: {
    loading: false,
    page: 1,
    limit: 10,
    sort: "ASC",
    doctorrequest: {},
    doctorrequests: [],
    totalpages: 1,
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value;
    },

    SET_DOCTOR_REQUEST(state, value) {
      state.doctorrequest = value;
    },

    SET_DOCTOR_REQUESTS(state, value) {
      state.doctorrequests = value;
    },

    SET_PAGE(state, value) {
      state.page = value;
    },

    SET_TOTAL_PAGES(state, value) {
      state.totalpages = value;
    },
  },
  actions: {
    fetchDoctorRequests: async ({ commit }) => {
      try {
        commit("SET_LOADING", true);
        // const response = await requestService.fetchDoctorRequests(
        //   `/admin/view-signup-requests?page=${rootState.request.page}&size=${rootState.request.limit}&sort=${rootState.request.sort}`
        // );
        const response = await requestService.fetchDoctorRequests(
          `doctor/allrequest`
        );
        commit("SET_LOADING", false);
        console.log("response.data=>", response.data)

        // commit("SET_TOTAL_PAGES", response.data.totalPages);
        // commit("SET_DOCTOR_REQUESTS", removeDuplicates(response.data.content));
        commit("SET_DOCTOR_REQUESTS", removeDuplicates(response.data));
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    fetchSingleDoctorRequest: async ({ commit }, value) => {
      try {
        commit("SET_LOADING", true);
        const response = await requestService.fetchDoctorRequests(
          `/doctor/` + value
        );
        commit("SET_LOADING", false);
        commit("SET_DOCTOR_REQUEST", response.data);
      } catch (error) {
        commit("SET_LOADING", false);
        handleError(error.message);
      }
    },

    handlePagination: async ({ rootState, commit }, value) => {
      try {
        window.scrollTo(0, 0);
        commit("SET_LOADING", true);
        commit("SET_PAGE", value);
        const response = await requestService.fetchDoctorRequests(
          `/admin/view-signup-requests?page=${value}&size=${rootState.request.limit}&sort=${rootState.request.sort}`
        );
        commit("SET_DOCTOR_REQUESTS", removeDuplicates(response.data.content));
        commit("SET_LOADING", false);
      } catch (error) {
        handleError(error.message);
        commit("SET_LOADING", false);
      }
    },
  },
};
