import axios from "axios";

export const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});
export const azureApi=axios.create({
  baseURL: process.env.VUE_APP_AZURE_API_URL,
})
export const adminApi=axios.create({
  baseURL: process.env.VUE_APP_ADMIN_API_URL,
})
export const appointmentApi=axios.create({
  baseURL: process.env.VUE_APP_APPOINTMENT_API_URL,
})
