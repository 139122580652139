import {  azureApi } from "@/api/api";
// import { HEADERS } from "@/constants/constants";

export default {
  // async fetchDoctorRequests(url) {
  //   return api.get(url, {
  //     headers: HEADERS,
  //   });
  // },
  // async fetchDoctorRequests(url) {
  //   return azureApi.get(url);
  // },
  async fetchDoctorRequests(url) {
    return azureApi.get(url);
  },


  async updateRequest(url, data) {
    return azureApi.put(url, data);
  },
};
