<script>
import { mapState } from "vuex";

// components
import DashboardLayout from "@/layouts/dashboardlayout/DashboardLayout.vue";
import OverlayLoader from "@/elements/Loader/OverlayLoader.vue";

// utils
import { handleError, handleSuccess } from "@/utils/handleResponse";
import requestService from "@/services/Requests/requestService";

export default {
  props: ['id'],

  components: {
    DashboardLayout,
    OverlayLoader
  },

  data() {
    return {
      isLoading: false,
    };
  },

  methods: {
    async handleAccept() {
      this.isLoading = true;
      try {
        const response = await requestService.updateRequest(
          `doctor/regstatus/${this.doctor.data.id}`,
          {
            action: "Accept",
            doctor_reg_status: "ACCEPT",
          }
        );
        console.log("response==>", response.data?.message);
        handleSuccess(response.data?.message);
        this.isLoading = false;
        // this.$emit("closeModal");
        // this.$store.dispatch("request/fetchDoctorRequests");
        this.$router.push("/doctor-request");
      } catch (error) {
        handleError(error.message);
        this.isLoading = false;
      }
    },
    
    async handleReject() {
      this.isLoading = true;
      try {
        const response = await requestService.updateRequest(
          `doctor/regstatus/${this.doctor.data.id}`,
          {
            action: "Reject",
            doctor_reg_status: "REJECT",
          }
        );
        handleSuccess(response?.data?.message);
        this.isLoading = false;
        // this.$emit("closeModal");
        // this.$store.dispatch("request/fetchDoctorRequests");
        this.$router.push("/doctor-request");
      } catch (error) {
        handleError(error.message);
        this.isLoading = false;
      }
    },
  },

  computed: {
    ...mapState("request", {
      loading: (state) => state.loading,
      doctor: (state) => state.doctorrequest,
    }),

    date_of_birth() {
      return new Date(this.doctor.data.birth_day).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    },

    doctorDetails() {
      if (!this.doctor || !this.doctor.data) return [];

      return [
        { label: "First Name", value: this.doctor.data.first_name },
        { label: "Last Name", value: this.doctor.data.last_name },
        { label: "Email Address", value: this.doctor.data.email },
        { label: "Phone", value: this.doctor.data.phone_number },
        { label: "Gender", value: this.doctor.data.gender },
        { label: "DOB", value: this.date_of_birth },
        { label: "Qualification", value: this.doctor.data.qualification },
        { label: "Practice Number", value: this.doctor.data.practice_number },
        { label: "Area of Specialization", value: this.doctor.data.speciality },
        { label: "Language", value: this.doctor.data.language },
        { label: "Experience", value: `${this.doctor.data.experience_year} years` },
        // { label: "Appointment Cost", value: `₦${this.doctor.data.appointment_cost}` },
      ];
    },
  },

  created() {
    this.$store.dispatch("request/fetchSingleDoctorRequest", this.id);
  },
};
</script>

<template>
  <DashboardLayout>
    <!-- Back Button -->
    <div class="py-4 px-6 bg-gray-100">
      <button @click="$router.back()" class="flex items-center text-gray-600 hover:text-gray-800 transition">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
          class="w-5 h-5 mr-2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
        Back
      </button>
    </div>

    <!-- Loading State -->
    <div v-if="loading" class="min-h-screen flex items-center justify-center">
      <p>Loading...</p>
    </div>


    <!-- Doctor Detail -->
    <div v-else-if="doctor && doctor.data" class="min-h-screen bg-gray-100 p-6">
      <div class="max-w-4xl mx-auto bg-white rounded-lg shadow-md overflow-hidden">
        <!-- Header Section -->
        <div class="flex flex-col items-center justify-between p-6 bg-betacolor text-white md:flex-row">
          <div class="flex items-center">
            <!-- <img :src="doctor.data.image_url" alt="Doctor Image"
              class="w-24 h-24 rounded-full object-cover border-4 border-white" /> -->
            <div class="ml-6">
              <h1 class="text-2xl font-bold">
                {{ doctor.data.first_name }} {{ doctor.data.last_name }}
              </h1>
              <p class="text-sm">{{ doctor.data.qualification }}</p>
              <p class="text-sm">{{ doctor.data.speciality }}</p>
            </div>
          </div>

          <!-- Action Buttons -->
          <div class="space-x-4">
            <button @click="handleAccept" class="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600">
              Accept
            </button>
            <button @click="handleReject" class="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600">
              Reject
            </button>
          </div>
        </div>

        <!-- Info Section -->
        <div class="p-6">
          <!-- Bio -->
          <!-- <div class="mb-12">
            <h2 class="text-lg font-semibold text-gray-800">Bio</h2>
            <p class="text-gray-600">{{ doctor.data.bio }}</p>
          </div> -->

          <!-- Key Details -->
          <div class="mb-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-6 md:gap-y-12">
            <div v-for="(detail, index) in doctorDetails" :key="index" class="space-y-1">
              <p class="mb-0 text-gray-500">{{ detail.label }}</p>
              <p class="font-medium">{{ detail.value }}</p>
            </div>
          </div>

          <!-- Rating -->
          <!-- <div class="">
            <h2 class="text-lg font-semibold text-gray-800">Ratings</h2>
            <p class="text-gray-600">
              Average Rating: {{ doctor.data.average_rating }} ⭐️ ({{ doctor.data.total_ratings }} reviews)
            </p>
          </div> -->

          <!-- Documents -->
          <div>
            <h2 class="text-lg font-semibold text-white">Documents</h2>
            <div class="space-y-4">
              <a :href="doctor.data.curriculum_vitae" target="_blank"
                class="block px-4 py-2 text-center text-white rounded shadow hover:bg-betacolor hover:text-white transition">
                View Curriculum Vitae
              </a>
              <a :href="doctor.data.valid_id_card" target="_blank"
                class="block px-4 py-2 text-center text-white rounded shadow hover:bg-betacolor hover:text-white transition">
                View Valid ID Card
              </a>
              <a :href="doctor.data.valid_license" target="_blank"
                class="block px-4 py-2 text-center text-white rounded shadow hover:bg-betacolor hover:text-white transition">
                View Valid License
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No Doctor Data -->
    <div v-else class="min-h-screen flex items-center justify-center">
      <div class="text-center">
        <h2 class="text-2xl font-bold text-gray-800">No Doctor Data Available</h2>
        <p class="text-gray-600 mt-2">
          The requested doctor data could not be found. Please try again or contact support if the issue persists.
        </p>
      </div>
    </div>
    <overlay-loader :loading="isLoading" />
  </DashboardLayout>
</template>
