import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";
const firebaseConfig = {
    apiKey: 'AIzaSyBUeAg1vXDMGAfZ0CeR0bkIOKxXfnVVEWA',
    authDomain: 'betacare-a27b2.firebaseapp.com',
    projectId: 'betacare-a27b2',
    storageBucket: 'betacare-a27b2.appspot.com',
    messagingSenderId: '219014335012',
    appId: '1:219014335012:web:c4c3f51cee44ba400666b1',
};
firebase.initializeApp(firebaseConfig);
export default firebase;
