<template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <!-- <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn> -->
    </div>

    <div>
      <!-- <h2 class="my-3 text-center font-weight-bold">Article's Details</h2> -->

      <v-row>
        <v-col cols="12" class="mb-2">
          <div class="font-semibold grey--text">Email Subject</div>
          <div class="text-lg" :class="{ 'grey--text': !contentData?.email_subject }">
            {{ contentData?.email_subject}}
          </div>
        </v-col>
        <v-col cols="12" class="mb-2">
          <div class="font-semibold grey--text">Email Body</div>
          <div
            :class="{ 'grey--text': !contentData?.email_body }"
            v-html="contentData?.email_body"
          ></div>
        </v-col>
      </v-row>
    </div>

  </v-card>
</template>
<script>



export default {
  
  props: {
    contentData: {
      type: Object,
      default: () => ({}),
    },
    status: {
      type: String,
      default: "All",
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
  

  
  },
};
</script>
<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}

.image-preview-container {
  height: "200px";
  width: "200px";
  display: "flex";
  justify: "center";
}
.image-preview {
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.button-grid {
  display: flex;
  justify-content: space-between;
}
</style>
