<template>
  <v-card class="pt-2 px-6 pb-4">
    <v-form ref="form" v-model="formValid">
      <!-- Email Subject and Campaign Name on the Same Row -->
      <v-row class="my-4">
        <v-col cols="12" md="12" lg="6">
          <div class="my-2">Campaign Name</div>
          <v-text-field
            v-model="campaignName"
            outlined
            dense
            :rules="[(v) => !!v || 'Campaign name is required']"
            required
          />
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <div class="my-2">Email Subject</div>
          <v-text-field
            v-model="emailSubject"
            outlined
            dense
            :rules="[(v) => !!v || 'Email subject is required']"
            required
          />
        </v-col>
      </v-row>

      <!-- Email Content -->
      <div class="my-4">
        <div class="my-2">Enter Email Content</div>
        <div>
          <quill-editor v-model="sms" :options="editorOptions" />
          <span v-if="smsError" class="error-message">Email content is required</span>
        </div>
      </div>

      <!-- Date and Hour on the Same Row -->
      <v-row class="my-4">
        <v-col cols="12" md="12" lg="6">
          <div class="my-2">Select Date</div>
          <v-text-field
            v-model="date"
            type="date"
            outlined
            dense
            :rules="[(v) => !!v || 'Date is required']"
            required
          />
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <div class="my-2">Select Hour</div>
          <v-select
            v-model="selectedHour"
            :items="hours"
            outlined
            dense
            label="Hour"
            :rules="[(v) => !!v || 'Hour is required']"
            required
          />
        </v-col>
      </v-row>
    </v-form>

    <!-- Submit Button -->
    <div class="customButton">
      <PrimaryButton
        :loading="loader"
        :color="BRANDCOLOR"
        :large="true"
        :block="true"
        @click="validateAndSubmit"
      >
        Save
      </PrimaryButton>
    </div>
  </v-card>
</template>

<script>
import { BRANDCOLOR } from "@/constants/constants";
import PrimaryButton from "../Buttons/PrimaryButton.vue";
import { quillEditor } from "vue-quill-editor";
import { handleError, handleSuccess } from "@/utils/handleResponse";
import dailytextService from "@/services/DailyText/dailytextService";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    PrimaryButton,
    quillEditor,
  },
  data() {
    return {
      BRANDCOLOR,
      sms: "",
      date: "",
      selectedHour: "",
      emailSubject: "",
      campaignName: "", // New field for campaign name
      smsError: false,
      formValid: false,
      loader: false,
      hours: this.generateHours(),
      editorOptions: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["link"],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
    };
  },

  methods: {
    // generateHours() {
    //   const hours = [];
    //   for (let i = 1; i <= 12; i++) {
    //     hours.push(`${i} AM`);
    //   }
    //   for (let i = 1; i <= 12; i++) {
    //     hours.push(`${i} PM`);
    //   }
    //   return hours;
    // },

    generateHours() {
      const hours = [];

      // Loop through 24 hours (0 to 23)
      for (let hour = 0; hour < 24; hour++) {
        // Format the hour in 12-hour AM/PM format
        let period = hour < 12 ? "AM" : "PM";
        let displayHour = hour % 12 === 0 ? 12 : hour % 12; // Convert 24-hour to 12-hour format

        // Loop for 15-minute intervals
        for (let minutes of ["00", "15", "30", "45"]) {
          hours.push(`${displayHour}:${minutes} ${period}`);
        }
      }

      return hours;
    },

    validateAndSubmit() {
      // Validate the Quill Editor content
      this.smsError = this.sms.trim() === "";

      // Check if the form is valid
      if (this.$refs.form.validate() && !this.smsError) {
        // Submit the form if validation passes
        this.postBulkEMAIL();
      }
    },

    // async postBulkEMAIL() {
    //   try {
    //     this.loader = true;

    //     // Split the time and convert it to a 24-hour format

    //     const [hourString, period] = this.selectedHour.split(" ");

    //     // Parse the hour as a number
    //     const localHour = parseInt(hourString, 10);

    //     // Step 1: Convert local to UTC hour
    //     const hour = this.convertLocalToUtcHour(localHour, period);

    //     const response = await dailytextService.postBulkEMAIL(
    //       `patient/api/email-messages`,
    //       {
    //         email_subject:this.emailSubject,
    //         email_body:this.sms,
    //         scheduled_date:this.date,
    //         scheduled_hour: hour,
    //         compaign_name:this.campaignName,
    //       }
    //     );

    //     handleSuccess(response.data.message);
    //     this.loader = false;
    //     this.$store.dispatch("dailytext/fetchDailyTexts", `patient/api/email-messages`);
    //       this.$router.push("/daily-text");

    //   } catch (error) {
    //     handleError(error.message);
    //     this.loader = false;
    //   }

    //   // this.$emit(
    //   //   "postBulkSMS",
    //   //   this.sms,
    //   //   this.date,
    //   //   this.selectedHour,
    //   //   this.emailSubject,
    //   //   this.campaignName // Include campaign name
    //   // );
    // },

    //   convertLocalToUtcHour(localHour, amPm) {
    //   console.log("amPm",amPm)
    //   // Convert 12-hour format to 24-hour format
    //   let hour24 = amPm === "PM" ? (localHour % 12) + 12 : localHour % 12;

    //   // Create a date object for today with the local hour
    //   const date = new Date();
    //   date.setHours(hour24, 0, 0, 0); // Set the local hour

    //   // Get the UTC hours from the date object
    //   const utcHour = date.getUTCHours();
    //   return utcHour;
    // },

    async postBulkEMAIL() {
      try {
        this.loader = true;

        // Split the time (e.g., "12:30 PM") and period (AM/PM)
        const [hourMinuteString, period] = this.selectedHour.split(" ");

        // Split hour and minute (e.g., "12:30" -> ["12", "30"])
        const [localHour, minute] = hourMinuteString.split(":").map(Number);

        // Step 1: Convert local hour and minute to UTC time
        const { hour: utcHour, minute: utcMinute } = this.convertLocalToUtcHour(
          localHour,
          minute,
          period
        );

        console.log("`${utcHour}:${utcMinute}`", `${utcHour}:${utcMinute}`);
        // Create the email message payload with UTC time
        const response = await dailytextService.postBulkEMAIL(
          `patient/api/email-messages`,
          {
            email_subject: this.emailSubject,
            email_body: this.sms,
            scheduled_date: this.date,
            scheduled_hour: `${utcHour}:${utcMinute}`, // Set UTC hour and minute
            campaign_name: this.campaignName,
          }
        );
        handleSuccess(response.data.message);
        this.loader = false;
        this.$store.dispatch("dailytext/fetchDailyTexts", `patient/api/email-messages`);
        this.$router.push("/daily-text");
      } catch (error) {
        handleError(error.message);
        this.loader = false;
      }
    },

    convertLocalToUtcHour(localHour, minute, amPm) {
      // Convert 12-hour format to 24-hour format
      let hour24 = amPm === "PM" ? (localHour % 12) + 12 : localHour % 12;

      // Create a date object for today with the local hour and minute
      const date = new Date();
      date.setHours(hour24, minute, 0, 0); // Set the local hour and minute

      // Get the UTC hours and minutes from the date object
      const utcHour = date.getUTCHours();
      const utcMinute = date.getUTCMinutes();

      return { hour: utcHour, minute: utcMinute };
    },
  },
};
</script>

<style scoped>
.ql-editor {
  min-height: 200px;
  max-height: 400px;
  overflow-y: auto;
}
.customButton {
  width: max-content;
  padding: 4px;
  margin-left: auto;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}

.my-4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-2 {
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>
