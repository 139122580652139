
<template>
  <v-card class="pt-4 px-6 pb-8 custom-card">
    <div class="d-flex justify-end">
      <v-btn @click.native="$emit('closeModal')" depressed icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div>
      <div class="my">
        <h2 class="text-center font-weight-bold">Follow Up Message Details</h2>
      </div>
      <v-row>
        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Title:</div>
          <div class="text-lg">{{  detailFollow?.title }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showData">
          <div class="font-semibold grey--text">Status:</div>
          <div class="text-lg">{{  detailFollow?.status }}</div>
        </v-col>

        <v-col cols="12" class="mb-2 showDat">
          <div class="font-semibold grey--text">Description</div>
          <div class="text-lg">{{  detailFollow?.description }}</div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
     detailFollow: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
  
  },
};
</script>

<style scoped>
.custom-card {
  margin: auto;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
  max-width: 600px;
}

.my {
  margin-bottom: 30px;
}

.text-center {
  text-align: center;
}

.font-weight-bold {
  font-weight: bold;
}

.font-semibold {
  font-weight: 600;
}

.grey--text {
  color: grey;
}

.showData {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
